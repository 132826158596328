/** @jsx jsx */
import { useCallback, useRef } from 'react';
import { jsx, Flex, Box } from 'theme-ui';

const sizes = {
  small: {
    track: {
      width: 48,
      height: 24,
    },
    thumb: {
      width: 16,
      height: 16,
    },
  },
  large: {
    track: {
      width: 56,
      height: 32,
    },
    thumb: {
      width: 24,
      height: 24,
    },
  },
};

const Switch = ({ checked, onChange, children, size = 'small', ...props }) => {
  const inputRef = useRef();
  const onKeyDown = useCallback(
    e => {
      if (e.key === ' ' || e.key === 'Enter') {
        inputRef.current && inputRef.current.click();
        e.preventDefault();
      }
    },
    [inputRef]
  );

  return (
    <Flex
      as="label"
      sx={{
        'input:checked + div': {
          bg: 'primary',
          div: {
            transform: 'translateX(24px)',
          },
        },
      }}
      tabIndex="0"
      role="switch"
      aria-checked={checked}
      onKeyDown={onKeyDown}
    >
      <input
        ref={inputRef}
        checked={checked}
        role="switch"
        aria-checked={checked}
        onChange={onChange}
        type="checkbox"
        sx={{ height: 0, width: 0, visibility: 'hidden', m: 0 }}
        {...props}
      />
      <Flex
        sx={{
          flex: 'none',
          position: 'relative',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: props.disabled ? 'default' : 'pointer',
          borderRadius: 3,
          bg: 'border',
          opacity: props.disabled ? 0.4 : 1,
          transition: 'background-color 0.2s ease',
          ...sizes[size].track,
        }}
      >
        <Box
          sx={{
            content: '""',
            position: 'absolute',
            top: 1,
            left: 1,

            borderRadius: 3,
            bg: 'white',
            transition: '0.2s',
            ...sizes[size].thumb,
          }}
        ></Box>
      </Flex>
      <Box sx={{ flex: 1, ml: 2, fontSize: 2, color: 'black' }}>{children}</Box>
    </Flex>
  );
};

export default Switch;
