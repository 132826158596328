/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui';
import React from 'react';
import { capitalize } from '../utils/string';
import Button from '../components/Button';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import ua from 'ua-parser-js';
import { Link } from '../components';
import { useMemo } from 'react';
import { useLanguage } from '../utils/getLanguage';

export const STEP = {
  START: 0,
  BASIC_INFO: 1,
  DELIVERY_INSTRUCTIONS: 2,
  READY: 3,
  NOT_FOUND: 5,
  INVALID_PARAMETERS: 6,
  ARE_YOU_SURE_TO_SERVICE_POINT: 7,
  THANKS_TO_SERVICE_POINT: 8,
  ARE_YOU_SURE_NOT_RECEIVE: 10,
  THANKS_NOT_RECEIVE: 11,
  WOLT_PAYMENT: 12,
  WOLT_PAYMENT_OK: 13,
  AGREED: 14,
  EDIT: 15,
  MAP: 16,
  CHOOSE_SERVICE_POINT: 17,
};

export const formatAddress = address => {
  const postalCode = /\d{5} /.exec(address || '');
  if (!postalCode) {
    return address || '';
  }

  return (
    <Box>
      <Box as="span" sx={{ display: 'block' }}>
        {address.substr(0, postalCode.index).trim()}
      </Box>
      <Box as="span" sx={{ display: 'block' }}>
        {capitalize(address.substr(postalCode.index).trim())}
      </Box>
    </Box>
  );
};

export const BackButton = ({ goBack }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Box>
      <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
        {translate('delivery.agree.back')}
      </Button>
    </Box>
  );
};

export const BlueInfoBox = ({ deliveryAddress, day, time, shipment }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const address = formatAddress(deliveryAddress);
  const language = useLanguage();

  const [deliveryAccurateStart, deliveryAccurateEnd] = useMemo(() => {
    return [
      moment(shipment?.estimatedDeliveryStartTime)
        .tz('Europe/Helsinki')
        .locale(language),
      moment(shipment?.estimatedDeliveryEndTime)
        .tz('Europe/Helsinki')
        .locale(language),
    ];
  }, [shipment, language]);

  const accurateDateText = useMemo(() => {
    if (!deliveryAccurateStart) {
      return null;
    }

    return moment().isSame(deliveryAccurateStart, 'day')
      ? translate('delivery.agree.today')
      : moment(deliveryAccurateStart).format('DD.MM.YYYY');
  }, [deliveryAccurateStart]);

  return (
    <Box sx={{ bg: 'blueLighter', p: 3, borderRadius: 1, color: 'black' }}>
      {address}
      {shipment?.estimatedDeliveryStartTime ? (
        <>
          <br />
          <br />
          <span sx={{ fontWeight: 'medium' }}>
            {accurateDateText} {translate('delivery.agree.atTime')} {deliveryAccurateStart.format('HH:mm')} -{' '}
            {deliveryAccurateEnd.format('HH:mm')}.
          </span>
        </>
      ) : time ? (
        <>
          <br />
          <br />
          <span sx={{ fontWeight: 'medium' }}>
            {moment(day).format('dddd D.M.YYYY')}, {time.label}
          </span>
        </>
      ) : null}
    </Box>
  );
};

export const ButtonBox = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '200px',
        justifySelf: 'flex-end',
        gridGap: 2,
        flex: 'none',
        justifyContent: ['flex-end', null, 'flex-start'],
        mt: 3,
      }}
    >
      {children}
    </Box>
  );
};

export const date2dayString = date => moment.tz(date, 'Europe/Helsinki').format('YYYY-MM-DD');

export const setupMomentWeekdays = language => {
  if (language == 'fi') {
    moment.updateLocale('fi', {
      weekdays: ['Sunnuntaina', 'Maanantaina', 'Tiistaina', 'Keskiviikkona', 'Torstaina', 'Perjantaina', 'Lauantaina'],
    });
    moment.locale('fi');
  } else if (language == 'sv') {
    moment.locale('sv');
  } else if (language == 'en') {
    moment.locale('en');
  }
};

export const CalenderLink = ({ icsBase64, shipmentNumber }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  if (!icsBase64) {
    return null;
  }
  return (
    <div sx={{ my: 3 }}>
      <a
        href={`data:text/calendar;base64;charset=utf-8,${icsBase64}`}
        download={translate('delivery.agree.filename', { shipmentNumber })}
        sx={{ textDecoration: 'underline' }}
      >
        {translate('delivery.agree.addCalendar')}
      </a>
    </div>
  );
};

const getAgreeDeliveryDeepLinkForApp = (shipmentNumber, token) => {
  const isTest = process.env.GATSBY_ACTIVE_ENV !== 'production';
  const prefix = isTest
    ? `https://mhpakettidev.page.link/?link=https://testwww.matkahuolto.fi/sovijakelu`
    : `https://paketit.matkahuolto.fi/?link=https://matkahuolto.fi/sovijakelu`;
  return `${prefix}/${shipmentNumber}/${token}`;
};

export const NavigationHeader = ({ shipmentNumber, token, goBack }) => {
  if (!shipmentNumber || !token) return null;
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Flex sx={{ justifyContent: goBack ? 'space-between' : 'flex-end' }}>
      {goBack && (
        <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
          {translate('delivery.agree.back')}
        </Button>
      )}

      {isAndroid() && (
        <Button
          as={Link}
          to={{
            link: getAgreeDeliveryDeepLinkForApp(shipmentNumber, token),
            external: true,
          }}
        >
          {translate('delivery.agree.openApp')}
        </Button>
      )}
    </Flex>
  );
};

const formatTime = time => {
  // input "09:30:00.000"
  // output "9.30"
  const shortTime = time.substr(3, 2) == '00' ? time.substr(0, 2) : time.substr(0, 5).replace(':', '.');
  if (shortTime[0] == '0') {
    return shortTime.substr(1);
  } else {
    return shortTime;
  }
};

export const formatTimeRange = ({ start, end }, translate) => {
  const klo = translate('delivery.agree.clock');
  return `${klo} ${formatTime(start)} - ${formatTime(end)}`;
};

export const isAndroid = () => {
  const agent = ua();
  return agent?.os?.name == 'Android';
};
