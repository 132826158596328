/** @jsx jsx */
import { jsx, Flex, Box, useThemeUI, Label } from 'theme-ui';
import React, { useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { FormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import FormField from './FormField';
import Spinner from './Spinner';
import * as api from '../utils/api';
import getLanguage from '../utils/getLanguage';
import { isFinlandOrAland } from '../utils/countryCode';

const getOfficeCode = p => `${p.code} ${p.name}`;
const getOfficeName = p => p.name;

const groupByName = codes => {
  const names = {};
  codes.forEach(c => {
    if (names[c.name]) {
      names[c.name].push(c.code);
    } else {
      names[c.name] = [c.code];
    }
  });
  return Object.keys(names)
    .sort()
    .map(n => ({ name: n, codes: names[n] }));
};

export default ({ country = 'FI', showCity = true, postcodeLabel }) => {
  const formikContext = useContext(FormikContext);
  const {
    values: { postcode, city },
    setFieldValue,
  } = formikContext;
  const language = useSelector(state => getLanguage(state));
  const translate = getTranslate(useSelector(state => state.localize));
  const [postalCodeOptions, setPostalCodeOptions] = useState([]);
  const [pcMenuIsOpen, setPcMenuIsOpen] = useState(false);
  const { theme } = useThemeUI();

  const loadPostalOffices = useCallback(
    codePrefix =>
      (async () => {
        if (codePrefix.length < 2) {
          return [];
        }
        setPcMenuIsOpen(true);
        const offices = await api.getPostOfficeByCode(codePrefix, country, language);
        setPostalCodeOptions(offices);
        return offices;
      })(),
    [country, language, setPostalCodeOptions]
  );

  const loadPostalOfficesByName = useCallback(
    namePrefix =>
      (async () => {
        if (namePrefix.length < 2) {
          return [];
        }
        const offices = await api.getPostalCodeByName(namePrefix, country, language);
        setPostalCodeOptions(offices);
        return groupByName(offices);
      })(),
    [country, language, setPostalCodeOptions]
  );

  const selectPostalCode = useCallback(
    v => {
      setPcMenuIsOpen(false);
      setFieldValue('postcode', v.code);
      setFieldValue('city', v.name);
      setFieldValue('postcode', v.code); // dunno why but formik validation seems to be buggy
    },
    [setFieldValue, setPcMenuIsOpen]
  );

  const handleManualPostalCodeChange = useCallback(
    (inputValue, { action }) => {
      if (action === 'input-change') {
        setFieldValue('postcode', inputValue, false);
      }
    },
    [setFieldValue]
  );

  const selectOfficeName = useCallback(
    v => {
      setFieldValue('city', v.name);
      if (v.codes.length === 1) {
        setFieldValue('postcode', v.codes[0]);
        setPostalCodeOptions([]);
        setPcMenuIsOpen(false);
      } else {
        setFieldValue('postcode', '');
        setPostalCodeOptions(v.codes.sort().map(c => ({ name: v.name, code: c })));
        setPcMenuIsOpen(true);
      }
    },
    [setFieldValue, setPostalCodeOptions, setPcMenuIsOpen]
  );

  const canEnterPostalCodeManually = () => {
    return country !== 'SE';
  };

  const selectStyles = {
    container: base => ({ ...base, width: '100%' }),
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      minHeight: 'auto',
      borderWidth: 2,
      borderStyle: 'solid',
      //borderColor: state.isFocused ? theme.colors.primary : theme.colors.border,
      padding: theme.space[2],
      //boxShadow: state.isFocused ? theme.boxShadow.inputFocus : 'none',
      ':hover': {
        borderColor: 'newBlue',
        //borderColor: state.isFocused ? theme.colors.primary : theme.colors.grayLight,
        //boxShadow: state.isFocused ? theme.boxShadow.inputFocus : theme.boxShadow.inputHover,
      },
    }),
    indicatorsContainer: base => ({ display: 'none' }),
    valueContainer: base => ({ ...base, padding: 0 }),
    input: base => ({
      ...base,
      width: '100%',
      margin: 0,
      padding: 0,
      border: 0,
    }),
    option: (base, state) => ({
      ...base,
      color: theme.colors.text,
      backgroundColor: state.isFocused ? theme.colors.blueLighter : 'none',
      ':hover': { backgroundColor: '#E5E8EF', cursor: 'pointer' },
    }),
  };

  // postal code api gets Finnish data from Posti
  if (!isFinlandOrAland(country)) {
    return (
      <Flex sx={{ flexWrap: 'wrap', mx: -2 }}>
        <Box sx={{ width: ['100%', 'auto'], px: 2 }}>
          {!canEnterPostalCodeManually() ? (
            <>
              <p>{translate('address.postcodeAutoSelection')}</p>
              <Flex sx={{ gap: 4 }}>
                {postcode && (
                  <Box sx={{ mb: 3 }}>
                    <Label>{translate('address.postcode')}</Label>
                    <div>{postcode}</div>
                  </Box>
                )}
                {showCity && city && (
                  <Box sx={{ mb: 3 }}>
                    <Label>{translate('address.city')}</Label>
                    <div>{city}</div>
                  </Box>
                )}
              </Flex>
            </>
          ) : (
            <>
              <Flex sx={{ gap: 2 }}>
                <FormField
                  name="postcode"
                  label={postcodeLabel || translate('address.postcode')}
                  focus={!!postcode}
                  prefix={country.toUpperCase() + '-'}
                />
                {showCity && (
                  <Box sx={{ px: 2, flex: '1 1 auto', width: ['100%', 'auto'] }}>
                    <FormField name="city" label={translate('address.city')} focus={!!city} />
                  </Box>
                )}
              </Flex>
            </>
          )}
        </Box>
      </Flex>
    );
  }

  return (
    <Flex>
      <div sx={{ minWidth: '25%', mr: 3, zIndex: 2 }}>
        <FormField
          name="postcode"
          label={postcodeLabel || translate('address.postcode')}
          value={{ code: postcode, name: '' }}
          focus={!!postcode}
          defaultOptions={postalCodeOptions}
          menuIsOpen={pcMenuIsOpen}
          as={AsyncSelect}
          loadOptions={loadPostalOffices}
          tabSelectsValue={true}
          getOptionLabel={getOfficeCode}
          onInputChange={handleManualPostalCodeChange}
          isOptionSelected={() => false}
          onChange={selectPostalCode}
          noOptionsMessage={() => null}
          loadingMessage={() => (
            <Box sx={{ py: 2 }}>
              <Spinner />
            </Box>
          )}
          styles={selectStyles}
          onFocus={() => setPcMenuIsOpen(true)}
          onBlur={() => setPcMenuIsOpen(false)}
        />
      </div>
      {showCity && (
        <div sx={{ flexGrow: 1, zIndex: 2 }}>
          <FormField
            name="city"
            label={translate('address.city')}
            style={{ marginLeft: '1em', flexGrow: '1' }}
            value={{ name: city, code: city }}
            focus={!!city}
            as={AsyncSelect}
            tabSelectsValue={true}
            loadOptions={loadPostalOfficesByName}
            getOptionLabel={getOfficeName}
            isOptionSelected={() => false}
            onChange={selectOfficeName}
            noOptionsMessage={() => null}
            loadingMessage={() => (
              <Box sx={{ py: 2 }}>
                <Spinner />
              </Box>
            )}
            styles={selectStyles}
          />
        </div>
      )}
    </Flex>
  );
};
