/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Payment from '../return/Payment';
import { isBrowser } from '../utils';
import * as analytics from '../utils/analytics';
import ReturnConfirmation from '../return/ReturnConfirmation';
import SelectType from '../return/SelectType';
import SelectPickupPoint from '../return/SelectPickupPoint';
import { createOrder } from '../return/order';
import { useMemo } from 'react';
import { getUnpaidCashPayments } from '../utils/shipment';
import Layout from '../components/layout';
import { Container, Spinner } from '../components';
import * as api from '../utils/api';
import ConfirmRecycle from '../return/ConfirmRecycle';

export default ({ location: { state } = {}, pageContext }) => {
  analytics.usePageCategory('paketit');
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const [page, setPage] = useState(0);
  const [returnType, setReturnType] = useState();
  const [pickupPoint, setPickupPoint] = useState();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const shipmentNumber = isBrowser && new URLSearchParams(location.search).get('shipment');
  const token = isBrowser && new URLSearchParams(location.search).get('token');

  useEffect(() => {
    if (!shipmentNumber || !token || data?.shipment) return;
    (async () => {
      try {
        setLoading(true);
        const response = await api.checkReturnToCustomer(shipmentNumber, token);
        setData(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return;
      }
    })();
  }, [shipmentNumber, token]);

  const paths = (pageContext && pageContext.paths) || {};
  const locale = (pageContext && pageContext.locale) || 'en';

  const duePayments = useMemo(() => getUnpaidCashPayments(data.shipment), [data]);

  const selectReturnType = useCallback(
    type => {
      setReturnType(type);
      if (type === 'destroy') {
        setPage(3);
      } else {
        setPage(1);
      }
    },
    [data, pickupPoint, dispatch]
  );

  const handleRecycleConfirm = useCallback(() => {
    dispatch(createOrder(data.shipment.shipmentNumber, 'destroy', null, { paymentMethod: 'FREE' }));
  }, [data]);

  const selectPickupPoint = useCallback(pp => {
    setPickupPoint(pp);
    setPage(2);
  }, []);

  const handleGoBack = useCallback(() => {
    setPage(page > 0 ? page - 1 : 0);
  }, [page, setPage]);

  return (
    <Layout
      title={translate('return.title')}
      paths={paths}
      locale={locale}
      showAppBanner="paketit"
      checkShopDisturbance={true}
    >
      <Container sx={{ maxWidth: 1024, py: [2, 3, null, 4] }}>
        {loading ? (
          <Loading />
        ) : data?.alreadySelected && data?.shipment ? (
          <ReturnConfirmation shipment={data.shipment} />
        ) : data?.shipment ? (
          <>
            {page === 0 ? (
              <SelectType state={state} price={duePayments} selectReturnType={selectReturnType} />
            ) : page === 1 ? (
              <SelectPickupPoint
                product={data?.product}
                selectPickupPoint={selectPickupPoint}
                onGoBack={handleGoBack}
              />
            ) : page === 2 ? (
              <Payment
                shipmentNumber={data?.shipment.shipmentNumber}
                returnType={returnType}
                pickupPoint={pickupPoint}
                goBack={handleGoBack}
              />
            ) : page === 3 ? (
              <ConfirmRecycle goBack={() => setPage(0)} onRecycleConfirm={handleRecycleConfirm} />
            ) : null}
          </>
        ) : (
          <div>{translate('return.invalidInfo')}</div>
        )}
      </Container>
    </Layout>
  );
};

const Loading = () => {
  return (
    <div sx={{ minHeight: '100px', position: 'relative' }}>
      <Spinner size="medium" />
    </div>
  );
};
