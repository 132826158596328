/** @jsx jsx */
import { jsx, Styled, Box, Flex } from 'theme-ui';
import React, { useCallback, useMemo } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { BlueInfoBox, ButtonBox, NavigationHeader, STEP } from '../home-delivery/utils';
import Button from '../components/Button';
import { pick } from 'lodash';

export const StartPage = ({ data, token, goToStep, updateSelection, resetSelection, selection }) => {
  const { shipment } = data || {};
  const translate = getTranslate(useSelector(state => state.localize));

  const selectedOption = useMemo(() => {
    return selection?.homeDeliveryAction;
  }, [selection]);

  const handleChangeOption = useCallback(action => {
    updateSelection({ homeDeliveryAction: action });
  }, []);

  const actions = {
    CHOOSE_TIME: 'choose-time',
    NOT_RECEIVE: 'not-receive',
    CHANGE_TO_PICKUP_POINT: 'change-to-pickup-point',
  };

  const handleSubmit = useCallback(() => {
    switch (selectedOption) {
      case actions.CHOOSE_TIME:
        resetSelection();
        goToStep(STEP.BASIC_INFO);
        break;
      case actions.NOT_RECEIVE:
        resetSelection({ notReceive: 'not-receive' });
        goToStep(STEP.ARE_YOU_SURE_NOT_RECEIVE);
        break;
      case actions.CHANGE_TO_PICKUP_POINT:
        resetSelection({ notReceive: 'service-point' });
        goToStep(STEP.CHOOSE_SERVICE_POINT);
        break;
    }
  }, [selectedOption, resetSelection]);

  const canChangeToServicePoint = !shipment?.personalHandover;

  return (
    <>
      <NavigationHeader shipmentNumber={data?.shipment?.shipmentNumber} token={token} />
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>{translate('account.parcels.received1')}</Styled.h1>
      <p>{translate('delivery.agree.shipment', pick(data.shipment, ['shipmentNumber', 'sender']))}</p>
      <BlueInfoBox deliveryAddress={data.shipment.deliveryAddress} />

      <Box sx={{ mt: 4 }}>
        <p sx={{ fontWeight: 'semibold' }}>{translate('delivery.agree.selectDelivery')}</p>
        <Option
          selectedOption={selectedOption}
          onChange={handleChangeOption}
          name={translate('delivery.agree.homeDeliveryActions.chooseTime')}
          value={actions.CHOOSE_TIME}
        />
        {shipment?.pickupAvailable && (
          <Option
            selectedOption={selectedOption}
            onChange={handleChangeOption}
            name={translate('delivery.agree.homeDeliveryActions.changeToPickupPoint')}
            value={actions.CHANGE_TO_PICKUP_POINT}
          />
        )}
        <Option
          selectedOption={selectedOption}
          onChange={handleChangeOption}
          name={translate('delivery.agree.homeDeliveryActions.notReceive')}
          value={actions.NOT_RECEIVE}
        />
        <Box sx={{ mt: 4, backgroundColor: 'lightYellow', px: 2, py: 1, borderRadius: 1 }}>
          <p sx={{ ml: 2, mr: 2 }}>{translate('delivery.agree.selectionBinding')}</p>
        </Box>
        <ButtonBox>
          <Button sx={{ mt: 4 }} onClick={handleSubmit} disabled={!selectedOption}>
            {translate('delivery.agree.continue')}
          </Button>
        </ButtonBox>
      </Box>
    </>
  );
};

const Option = ({ selectedOption, onChange, name, value }) => {
  return (
    <Box
      role="radio"
      tabIndex={0}
      onClick={() => onChange(value)}
      aria-checked={selectedOption === value}
      onKeyPress={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onChange(value);
        }
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: selectedOption === value ? 'primary' : 'border',
        alignItems: 'center',
        cursor: 'pointer',
        borderWidth: 2,
        borderRadius: 1,
        px: 3,
        py: 2,
        my: 2,
        flex: '1 1 auto',
      }}
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <p sx={{ fontWeight: selectedOption === value ? 'bold' : 'normal' }}>{name}</p>
      </Flex>
    </Box>
  );
};
