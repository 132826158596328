/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useEffect, useMemo } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import Switch from '../components/Switch';
import { useCallback } from 'react';
import { setPickup } from '../state/basket';
import * as catalog from '../state/catalog';
import { useCartHasOnlyExpressParcels } from '../state/basket';
import { formatPrice } from '../utils/formatters';
import { useState } from 'react';

const freePickup = {
  serviceCode: 'NO',
  price: 0,
  priceVat0: 0,
};

export default () => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const [freePickupRejected, setFreePickupRejected] = useState(false);
  const mvpPickup = useSelector(state => {
    const noServices = state.basket.items
      .map(i => ((i.product && i.product.services) || []).find(s => s.serviceCode === 'NO'))
      .filter(s => !!s);
    return noServices[0];
  });
  const selectedPickup = useSelector(state => state.basket.noPickup === false);
  const basketHasNormalParcel = useSelector(state =>
    state.basket.items.some(
      i => !i.buySeries && ![catalog.SizeCode.REPACK, catalog.SizeCode.REFILL].includes(i.product.sizeCode)
    )
  );
  const basketHasRepackParcel = useSelector(state =>
    state.basket.items.some(i => !i.buySeries && i.product.sizeCode === catalog.SizeCode.REPACK)
  );
  const basketHasRefillParcel = useSelector(state =>
    state.basket.items.some(i => !i.buySeries && i.product.sizeCode === catalog.SizeCode.REFILL)
  );
  const basketHasSeriesItem = useSelector(state => state.basket.items.some(i => i.buySeries));
  const anyParcelIncludesPickup = useSelector(state =>
    state.basket.items.some(
      item =>
        !item.buySeries && (item.product.pickupIncluded || (item.services || []).some(s => s.override?.pickupIncluded))
    )
  );
  const onlyExpressParcels = useCartHasOnlyExpressParcels();

  const onChange = useCallback(
    e => {
      if (e.target.checked) {
        dispatch(setPickup(anyParcelIncludesPickup ? freePickup : mvpPickup));
        setFreePickupRejected(false);
      } else {
        setFreePickupRejected(anyParcelIncludesPickup);
        dispatch(setPickup(null));
      }
    },
    [dispatch, mvpPickup, anyParcelIncludesPickup]
  );

  const price = anyParcelIncludesPickup ? 0 : mvpPickup.price;

  const pickupIsOn = useMemo(() => {
    return anyParcelIncludesPickup || !!selectedPickup;
  }, [anyParcelIncludesPickup, selectedPickup]);

  useEffect(() => {
    if (onlyExpressParcels && selectedPickup) {
      dispatch(setPickup(null));
    }
    if (selectedPickup) {
      dispatch(setPickup(mvpPickup));
    }
  }, [onlyExpressParcels, dispatch, selectedPickup]);

  useEffect(() => {
    if (anyParcelIncludesPickup && !freePickupRejected) {
      dispatch(setPickup(freePickup));
    }
  }, [anyParcelIncludesPickup]);

  const showPickupOption =
    !onlyExpressParcels &&
    (mvpPickup || anyParcelIncludesPickup) &&
    (selectedPickup || basketHasNormalParcel || (basketHasRefillParcel && !basketHasRepackParcel));
  if (!showPickupOption) {
    return null;
  }

  return (
    <Flex sx={{ width: '100%', alignItems: 'flex-start', my: 4 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Switch defaultChecked={pickupIsOn} checked={selectedPickup} onChange={onChange}>
          <span sx={{ fontWeight: 'medium' }}>{translate('basket.pickup.label', { price: formatPrice(price) })}</span>
        </Switch>
        {pickupIsOn && (
          <Box sx={{ maxWidth: '560px', bg: 'blueLighter', px: 3, py: 1, borderRadius: 2, my: 2 }}>
            <p>{translate('basket.pickup.note1')}</p>
            <p>{translate('basket.pickup.note2')}</p>
          </Box>
        )}
        {freePickupRejected ? (
          <Box sx={{ bg: 'blueLighter', px: 3, py: 1, borderRadius: 2, my: 2 }}>
            <p>{translate('basket.pickup.freePickupRejected')}</p>
          </Box>
        ) : null}
        {basketHasRepackParcel && (
          <Box sx={{ maxWidth: '560px', bg: 'paleYellow', px: 3, py: 1, borderRadius: 2, my: 2 }}>
            <p>{translate('basket.pickup.noRePack')}</p>
          </Box>
        )}
        {basketHasSeriesItem && (
          <Box sx={{ maxWidth: '560px', bg: 'paleYellow', px: 3, py: 1, borderRadius: 2, my: 2 }}>
            <p>{translate('basket.pickup.noSeries')}</p>
          </Box>
        )}
      </Box>
      {selectedPickup && <div sx={{ color: 'primary', fontWeight: 'medium' }}>{formatPrice(price)}</div>}
    </Flex>
  );
};
