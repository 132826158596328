/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCards } from '../state/session';
import { PickupForm } from '../parcelFlow/PickupPoint';
import { useState } from 'react';

export default ({ selectPickupPoint, onGoBack, product }) => {
  const dispatch = useDispatch();

  const [pickupPoint, setPickupPoint] = useState();

  const handleSubmit = useCallback(() => {
    selectPickupPoint(pickupPoint);
  }, [selectPickupPoint, pickupPoint]);

  // preload user saved payment cards
  const user = useSelector(state => state.session.user);
  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const handleSelect = useCallback(v => {
    setPickupPoint(v);
  }, []);

  const props = {
    onBackClick: onGoBack,
    onNextClick: handleSubmit,
    onSelect: handleSelect,
    country: 'FI',
    pickupPoint: pickupPoint || {},
    title: 'return.choosePickupPoint',
    product,
  };

  return <PickupForm {...props} />;
};
