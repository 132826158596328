/** @jsx jsx */
import { jsx, Styled, Box } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Form } from 'formik';
import FormField from './FormField';
import CheckboxField from './CheckboxField';
import { Field } from 'formik';
import RecaptchaButton from './RecaptchaButton';

const RegisterForm = ({ setFieldValue, errors, ...props }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Field
        component={CheckboxField}
        aria-label={translate('register.form.companySelection')}
        name="company"
        id="company"
        label={translate('register.form.companySelection')}
        sx={{ mb: 3 }}
      />
      <FormField hidden={!props.values.company} name="companyName" label={translate('register.form.companyName')} />
      <Box sx={{ display: 'grid', gridGap: 3, gridTemplateColumns: '1fr 1fr' }}>
        <FormField
          name="firstname"
          label={
            props.values.company ? translate('register.form.companyFirstname') : translate('register.form.firstname')
          }
        />
        <FormField
          name="lastname"
          label={
            props.values.company ? translate('register.form.companyLastname') : translate('register.form.lastname')
          }
        />
      </Box>
      <FormField name="email" label={translate('register.form.email')} />
      <FormField type="password" name="password" label={translate('register.form.password')} />
      <FormField type="password" name="passwordAgain" label={translate('register.form.passwordAgain')} />
      <FormField hidden={!props.values.company} name="vatNumber" label={translate('register.form.vatNumber')} />
      <FormField
        name="phoneNumber"
        label={
          props.values.company ? translate('register.form.companyPhoneNumber') : translate('register.form.phoneNumber')
        }
      />

      <Box>
        <Styled.p
          dangerouslySetInnerHTML={{ __html: translate('register.terms') }}
          sx={{ mb: 3, lineHeight: 'normal' }}
        ></Styled.p>

        <RecaptchaButton
          buttonText="register.form.registerButton"
          translate={translate}
          handleSubmit={props.handleSubmit}
          isSubmitting={props.isSubmitting}
          setFieldValue={setFieldValue}
          errors={props.errors}
        />
      </Box>
    </Form>
  );
};

export default RegisterForm;
