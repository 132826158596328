/** @jsx jsx */
import { jsx, Flex, Box, Styled, useColorMode, Container } from 'theme-ui';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Global } from '@emotion/core';
import GlobalStyle from '../styles/global';
import Header from './Header';
import Footer from './Footer';
import Notification from './Notification';
import SEO from '../components/SEO';
import { changeLocale } from '../state/session';
import CookieConsent from '../components/CookieConsent';
import SmartAppBanner from '../components/SmartAppBanner';
import ShopDisturbance from './ShopDisturbance';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import { useTranslate } from '../utils/getLanguage';

export default ({
  children,
  showAppBanner,
  path,
  paths,
  title,
  description,
  keywords,
  locale,
  updatedAt,
  alert,
  darkMode = false,
  showNavigation = true,
  navigation,
  hideNormalNavigation,
  parentSlug,
  footer = true,
  image,
  checkShopDisturbance,
  hideLocales,
}) => {
  const [, setColorMode] = useColorMode();
  const notifications = useSelector(state => state.notifications) || [];
  const [menu, setMenu] = useState(false);
  const sessionLocale = useSelector(state => state.session.locale);
  const dispatch = useDispatch();
  const translate = useTranslate();
  useEffect(() => {
    const loc = locale && locale.includes('-') ? locale.substring(0, locale.indexOf('-')) : locale;
    if (loc && loc !== sessionLocale) {
      dispatch(changeLocale(loc));
    }
  }, []);

  const pageAvailable = useMemo(() => {
    if (!hideLocales || !hideLocales?.length) {
      return true;
    }
    return !(hideLocales || []).includes(sessionLocale);
  }, [hideLocales, sessionLocale]);

  const path2 =
    (paths || {})[(locale || '').substr(0, 2) || 'fi'] ||
    (typeof window !== 'undefined' ? window.location.pathname : '');
  const pathFirstPart = path2.split('/')[1];

  let corporate = false;
  if (['yrityksille', 'foretagskunder', 'corporate-customers', 'yrityksille-jani-test'].includes(pathFirstPart)) {
    darkMode = true;
    corporate = true;
  }

  // this is wrong, code should not change state directly during render
  setColorMode(darkMode === true ? 'dark' : 'default');

  /* but
  // theme-ui stores color mode to local storage, >= 0.6 has option to disable it
  useEffect(() => {
    setColorMode(darkMode === true ? 'dark' : 'default');
  }, [darkMode, setColorMode]);
  */

  return (
    <Styled.root>
      {!!showAppBanner && <SmartAppBanner path={path} banner={showAppBanner} />}
      <Global styles={GlobalStyle} />
      <Flex
        sx={{
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <SEO
          title={title}
          description={description}
          keywords={keywords}
          locale={locale}
          updatedAt={updatedAt}
          paths={paths}
          image={image}
        />
        <Box as={SkipNavLink} sx={{ '&:focus': { zIndex: 101 } }}>
          {translate('skipToContent')}
        </Box>
        {/**alert && alert*/}
        <CookieConsent />
        {notifications.map(n => (
          <Notification key={n.id} notification={n} />
        ))}
        <Header
          menu={menu}
          setMenu={setMenu}
          paths={paths}
          showNavigation={showNavigation}
          navigation={navigation}
          minimal={hideNormalNavigation}
          parentSlug={parentSlug}
          hideLocales={hideLocales}
        />
        <SkipNavContent />
        {checkShopDisturbance ? (
          <ShopDisturbance locale={locale}>
            <main sx={{ flex: '1 1 auto', width: '100%', overflow: 'hidden' }}>{children}</main>
          </ShopDisturbance>
        ) : !pageAvailable ? (
          <main sx={{ flex: '1 1 auto', width: '100%', overflow: 'hidden' }}>
            <Container sx={{ textAlign: 'center', py: [5] }}>
              <Styled.h1>{translate('404.title')}</Styled.h1>
            </Container>
          </main>
        ) : (
          <main sx={{ flex: '1 1 auto', width: '100%', overflow: 'hidden' }} aria-hidden={menu}>
            {children}
          </main>
        )}
        {footer && <Footer corporate={corporate} aria-hidden={menu} />}
      </Flex>
    </Styled.root>
  );
};
