/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Link from '../components/Link';
import { formatPrice } from '../utils/formatters';
import { InfoIcon, CheckIcon } from '../components/Icon';

const addWeightProps = service => ({
  ...service,
  minWeight: service.fromWeightClass,
  maxWeight: service.toWeightClass || service.override?.weightClass,
});

export default ({ service, isSelected, disabled, extraContent, product, onKeyDown }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const translate2 = (id, fallback, params) => translate(id, params || {}, { onMissingTranslation: () => fallback });

  // must not show disabled description for mailbox delivery product
  const showDisabledDesc = disabled && !product?.mailboxDelivery;
  const description = translate2(
    `buyParcel.services.${service.serviceCode}.${showDisabledDesc ? 'disabled' : 'description'}`,
    service.description,
    addWeightProps(service)
  );
  const note = translate2(`buyParcel.services.${service.serviceCode}.note`, '');
  const link = service.serviceCode === 'VK' ? '/' : null; // todo vakuutus service link

  const includedInPrice = isSelected && disabled;

  return (
    <Flex
      tabIndex={0}
      onKeyDown={onKeyDown}
      sx={{
        bg: isSelected ? '#primary' : 'white',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: isSelected ? 'primary' : 'border',
        borderRadius: 1,
        color: disabled && !includedInPrice ? 'grayLight' : 'inherit',
        backgroundColor: includedInPrice ? 'newGrayLighter' : disabled ? 'grayLighter' : 'white',
        lineHeight: 'normal',
        ':hover': {
          borderColor: isSelected ? 'primary' : 'grayLight',
          cursor: 'pointer',
        },
      }}
    >
      {isSelected && (
        <Flex
          sx={{
            flex: 'none',
            alignItems: 'center',
            bg: 'primary',
            color: 'white',
            width: 20,
          }}
        >
          {!includedInPrice && <CheckIcon sx={{ width: 16, ml: '2px' }} />}
        </Flex>
      )}
      <Box sx={{ flex: '1 1 auto', p: 3 }}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            mb: description ? 2 : 0,
          }}
        >
          <Box sx={{ fontWeight: 'medium' }}>
            {translate2(`buyParcel.services.${service.serviceCode}.title`, service.displayName)}
          </Box>
          <Box
            sx={{
              flex: 'none',
              fontWeight: 'heading',
              color: 'primary',
            }}
          >
            {formatPrice(service.price)}
          </Box>
        </Flex>
        <Box sx={{ fontSize: 1 }}>
          {description}{' '}
          {includedInPrice && (
            <span sx={{ fontWeight: 'semibold' }}>{translate('buyParcel.services.includedInPrice')}</span>
          )}
          {link ? <Link to={link}>{translate('buyParcel.services.readMore')}</Link> : null}
        </Box>
        {isSelected && note && (
          <Flex sx={{ fontSize: 1, mt: 3 }}>
            <div sx={{ mr: 2, color: 'primary' }}>
              <InfoIcon />
            </div>
            {note}
          </Flex>
        )}
        {isSelected && extraContent}
      </Box>
    </Flex>
  );
};
