/** @jsx jsx */
import { jsx, Flex, Box, Styled, useThemeUI } from 'theme-ui';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ServicePointInfo from './ServicePointInfo';
import Alert from '../components/Alert';
import Button from '../components/Button';
import { SearchIcon } from '../components/Icon';
import Spinner from '../components/Spinner';
import FullHeightColumn from '../components/FullHeightColumn';
import { goBack, goNext, selectPickupPoint, ParcelType } from '../state/parcelFlow';
import AsyncSelect from 'react-select/async';
import ServicePointButton from './ServicePointButton';
import { prefetchPage } from '../state/session';
import { autocompleteAddress, getExpressParcelPoints, getPickupPoints, getServicePoints } from '../utils/api';
import getLanguage from '../utils/getLanguage';
import * as streetAndApartment from '../utils/streetAndApartment';
import { CountryCode, isFinlandOrAland } from '../utils/countryCode';

const ariaLabelForServicePoint = (sp, translate) => {
  return sp.officeName + ', ' + sp.officeStreetAddress + ', ' + sp.officeCity;
};

const ServicePointList = ({ servicePoints, selected, onSelect, setSelectMsgVisible }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const container = useRef();
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.servicePoint'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : servicePoints.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < servicePoints.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
      }
    },
    [servicePoints, setFocusIndex, onSelect, setSelectMsgVisible]
  );

  return (
    <Box
      ref={container}
      sx={{
        border: '2px solid',
        borderColor: 'border',
        borderRadius: 2,
        overflow: 'hidden',
      }}
      role="group"
      aria-label={translate('buyParcel.pickup.select')}
      onKeyDown={onKeyDown}
    >
      {servicePoints.map((s, index) => (
        <ServicePointButton
          key={s.officeCode}
          servicePoint={s}
          isSelected={s.officeCode === selected.officeCode}
          onSelect={() => {
            setSelectMsgVisible(false);
            onSelect(s);
          }}
          className="servicePoint"
          role="checkbox"
          aria-label={ariaLabelForServicePoint(s, translate)}
          aria-checked={s.officeCode === selected.officeCode}
          tabIndex={focusIndex === index ? 0 : -1}
          onKeyDown={e => {
            e.preventDefault();
            if (e.key === 'Enter') {
              setFocusIndex(index);
              onSelect(s);
            }
          }}
        />
      ))}
    </Box>
  );
};

export const PickupForm = ({
  pickupPoint,
  product,
  country,
  recipient,
  parcelType,
  isTransfer,
  exclude,
  onBackClick,
  onNextClick,
  onSelect,
  title = 'buyParcel.pickup.title',
  overrideSearchType,
  returnFormOnly,
}) => {
  const { theme } = useThemeUI();
  const language = useSelector(state => getLanguage(state));
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [servicePoints, setServicePoints] = useState([]);
  const [spLimit, setSpLimit] = useState(5); // show first 5 and "show more" button
  const hasMore = spLimit < servicePoints.length;
  const [originalPickupPoint, setOriginalPickupPoint] = useState(pickupPoint);
  const originalPickupPointIncluded = !!servicePoints.find(s => s.officeCode === originalPickupPoint.officeCode);
  const servicePoints2 =
    originalPickupPoint.officeCode && !originalPickupPointIncluded
      ? [originalPickupPoint, ...servicePoints.slice(0, spLimit)]
      : servicePoints.slice(0, spLimit);
  const currentSelectionInList = !!servicePoints2.find(s => s.officeCode === pickupPoint.officeCode);
  const [isSelectMsgVisible, setSelectMsgVisible] = useState(false);
  const preventDoubleClick = useRef(false);
  const showSkipButton = false; // !isFinlandOrAland(country) && servicePoints.length === 0;
  const isExpressParcel = useSelector(state => state.parcelFlow.parcelType === ParcelType.Express);
  const isFreeTransfer = useSelector(state => state.transfer?.freeTransfer);
  const toAbroad = !isFinlandOrAland(country);
  const showMore = useCallback(() => setSpLimit(n => n + 5), [setSpLimit]);
  const onNextClick2 = useCallback(async () => {
    if (!currentSelectionInList) {
      setSelectMsgVisible(true);
      return;
    }
    if (preventDoubleClick.current) {
      return;
    }
    preventDoubleClick.current = true;
    try {
      await onNextClick();
    } finally {
      preventDoubleClick.current = false;
    }
  }, [currentSelectionInList, onNextClick]);

  useEffect(() => {
    setOriginalPickupPoint(pickupPoint);
  }, [pickupPoint]);

  const skipSelection = useCallback(() => {
    onSelect({});
    preventDoubleClick.current = true;
    onNextClick();
  }, [onNextClick, onSelect]);
  dispatch(prefetchPage('/ostoskori'));

  const selectSuggestion = useCallback(
    async (opt, selectFirst = false) => {
      const postcode = (opt.postalCode || '').replace(/[^0-9]/g, '');
      const street = opt.streetAddress;
      const { isLarge, isExtraLarge, isHeavy } = product || {};
      setLoading(true);
      let servicePoints = [];
      if (parcelType === ParcelType.Express) {
        servicePoints = await getExpressParcelPoints({
          postcode,
          street,
          language,
        });
      } else if (isTransfer) {
        servicePoints = await getServicePoints(postcode, street, !isFreeTransfer ? '-EP' : '', language);
      } else {
        servicePoints = await getPickupPoints({
          postcode,
          street,
          country,
          isLarge: isLarge || undefined, // dont include if false
          isExtraLarge: isExtraLarge || undefined,
          isHeavy: isHeavy || undefined,
          overrideSearchType,
        });
      }
      setLoading(false);
      setSpLimit(5);

      const filteredPoints = servicePoints.filter(sp => !(exclude || []).includes(sp.officeCode));
      setServicePoints(filteredPoints);
      if (selectFirst && filteredPoints[0]) {
        onSelect(filteredPoints[0]);
      }
    },
    [
      product,
      country,
      setServicePoints,
      setSpLimit,
      setLoading,
      onSelect,
      parcelType,
      language,
      isTransfer,
      exclude,
      isFreeTransfer,
    ]
  );

  useEffect(() => {
    if (country === CountryCode.Finland) {
      if (recipient?.postcode) {
        selectSuggestion(
          {
            postalCode: recipient.postcode,
            streetAddress: streetAndApartment.combine(recipient),
          },
          true
        );
      }
      return;
    }
    if (country === CountryCode.Aland) {
      selectSuggestion(
        {
          postalCode: '22100',
        },
        true
      );
      return;
    }
    selectSuggestion(
      {
        postalCode: recipient.postcode,
        streetAddress: streetAndApartment.combine(recipient),
      },
      true
    );
  }, [country, recipient, selectSuggestion]);

  const selectStyles = {
    container: base => ({
      ...base,
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      minHeight: 'auto',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: state.isFocused ? theme.colors.primary : theme.colors.border,
      padding: theme.space[2],
      boxShadow: state.isFocused ? theme.boxShadow.inputFocus : 'none',
      ':hover': {
        borderColor: state.isFocused ? theme.colors.primary : theme.colors.grayLight,
        boxShadow: state.isFocused ? theme.boxShadow.inputFocus : theme.boxShadow.inputHover,
      },
    }),
    indicatorsContainer: base => ({ display: 'none' }),
    placeholder: base => ({ color: theme.colors.gray }),
    input: (base, state) => ({
      color: theme.colors.text,
    }),
    option: (base, state) => ({
      ...base,
      color: theme.colors.text,
      backgroundColor: state.isFocused ? theme.colors.blueLighter : 'none',
      ':hover': { backgroundColor: '#E5E8EF', cursor: 'pointer' },
    }),
  };

  const form = (
    <Flex
      sx={{
        flex: ['auto', null, 'none'],
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: 640,
        mt: 3,
      }}
    >
      {isExpressParcel && <Alert>{translate('buyParcel.express.toDescription')}</Alert>}
      <Box sx={{ flex: 'auto', mb: 3 }}>
        <Box sx={{ position: 'relative' }}>
          <SearchIcon sx={{ position: 'absolute', right: 16, top: 11 }} />
          <AsyncSelect
            placeholder={translate('buyParcel.pickup.search')}
            styles={selectStyles}
            loadOptions={term => autocompleteAddress(term, language, country)}
            getOptionLabel={opt => opt.name}
            isOptionSelected={() => false}
            onChange={selectSuggestion}
            noOptionsMessage={() => null}
            loadingMessage={() => (
              <Box sx={{ py: 2 }}>
                <Spinner />
              </Box>
            )}
          />
        </Box>
        {isSelectMsgVisible ? <div sx={{ color: 'danger', mt: 2 }}>{translate('buyParcel.pickup.select')}</div> : null}
        <Box sx={{ position: 'relative', mt: 4 }}>
          {isLoading ? (
            <Spinner />
          ) : servicePoints2.length === 0 ? (
            toAbroad ? (
              <CenteredHelpText>{translate('buyParcel.pickup.abroadNotFound')}</CenteredHelpText>
            ) : (
              <CenteredHelpText>{translate('buyParcel.pickup.description')}</CenteredHelpText>
            )
          ) : (
            <>
              <ServicePointList
                servicePoints={servicePoints2}
                selected={pickupPoint}
                onSelect={onSelect}
                setSelectMsgVisible={setSelectMsgVisible}
              />
              {hasMore && (
                <Flex sx={{ justifyContent: 'center' }}>
                  <Button variant="plain" onClick={showMore}>
                    {translate('buyParcel.pickup.showMore')}
                  </Button>
                </Flex>
              )}
            </>
          )}
        </Box>
      </Box>
      {isExpressParcel && <ServicePointInfo sp={pickupPoint} />}
      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
        }}
      >
        <Button sx={{ order: [1, 1, 0], mr: [0, 0, 2] }} disabled={servicePoints2.length === 0} onClick={onNextClick2}>
          {translate('buyParcel.continue')}
        </Button>
        {showSkipButton && (
          <Button sx={{ order: [0, 0, 1], mr: [2, 2, 0] }} variant="plain" onClick={skipSelection}>
            {translate('buyParcel.pickup.skipSelection')}
          </Button>
        )}
      </Flex>
    </Flex>
  );

  if (isTransfer || returnFormOnly) {
    return form;
  } else {
    return (
      <FullHeightColumn>
        <Box>
          <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
            {translate('buyParcel.backButton')}
          </Button>
        </Box>

        <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate(title)}</Styled.h1>
        {form}
      </FullHeightColumn>
    );
  }
};

const CenteredHelpText = ({ children }) => (
  <Styled.p
    sx={{
      p: [4],
      color: 'gray',
      textAlign: 'center',
    }}
  >
    {children}
  </Styled.p>
);

// above form is also used by own parcels > update recipient flow

// everything that depends on redux state
export default () => {
  const pickupPoint = useSelector(state => state.parcelFlow.pickupPoint) || {};
  const product = useSelector(state => state.parcelFlow.product);
  const country = useSelector(state => state.parcelFlow.country);
  const recipient = useSelector(state => state.parcelFlow.recipient);
  const parcelType = useSelector(state => state.parcelFlow.parcelType);

  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const onNextClick = useCallback(() => dispatch(goNext()), [dispatch]);
  const onSelect = useCallback(p => dispatch(selectPickupPoint(p)), [dispatch]);

  const props = { pickupPoint, product, country, recipient, parcelType, onBackClick, onNextClick, onSelect };
  return <PickupForm {...props} />;
};
