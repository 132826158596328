/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik, Form } from 'formik';
import * as yup from 'yup';
import { createOrder, saveSender } from '../state/basket';
import Button from '../components/Button';
import FormField from '../components/FormField';
import AutocompleteStreetField from '../components/AutocompleteStreetField';
import PostalCodeAndCityFields from '../components/PostalCodeAndCityFields';
import { showNotification } from '../state/notifications';
import { locNavigate, prefetchPage, getCards } from '../state/session';
import { getBasketId } from '../utils/basket';
import { setOrderId } from '../utils/order';
import { postalCodeRegExp } from '../utils/regExp';
import * as streetAndApartment from '../utils/streetAndApartment';
import { isBrowser } from '../utils';
import { isFinlandOrAland } from '../utils/countryCode';

function isBasketFree(basket) {
  if (!basket.discount) {
    return false;
  }

  let total = 0;
  (basket.items || []).forEach(item => {
    total += item.product.price || 0;
    (item.services || []).forEach(s => {
      total += s.price || 0;
    });
  });
  if (basket.pickup) {
    total += basket.pickup.price;
  }

  return basket.discount.amount >= total;
}

export const SenderForm = ({ handleSubmit, isSubmitting, noPayment, requireAddress, hasPickup }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <div sx={{ maxWidth: 640 }}>
        <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('buyParcel.sender.title')}</Styled.h1>
        <FormField name="name" label={translate('buyParcel.sender.name')} />
        <FormField name="phone" label={translate('buyParcel.sender.phone') + (requireAddress ? ' *' : '')} />
        <FormField name="email" label={translate('buyParcel.sender.email')} />
      </div>

      {requireAddress && (
        <>
          <div sx={{ maxWidth: 640 }}>
            {hasPickup && (
              <Styled.h3 sx={{ color: 'secondary', mt: 3 }}>{translate('buyParcel.sender.addrTitle')}</Styled.h3>
            )}
            <div style={{ display: 'flex' }}>
              <div sx={{ flexGrow: 1, mr: 3, zIndex: 3 }}>
                <AutocompleteStreetField label={translate('address.street')} country="FIorAX" />
              </div>
              <div sx={{ minWidth: '25%' }}>
                <FormField name="apartment" label={translate('address.apartment')} />
              </div>
            </div>
            <PostalCodeAndCityFields country="FIorAX" />
            {hasPickup && <FormField name="remarks" label={translate('buyParcel.sender.remarks')} />}
          </div>
          {hasPickup && (
            <p>
              <span dangerouslySetInnerHTML={{ __html: translate('buyParcel.sender.note1') }}></span>
              <br />
              {translate('buyParcel.sender.note2')}
            </p>
          )}
        </>
      )}

      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 3,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {noPayment ? translate('buyParcel.confirmNoPayment') : translate('buyParcel.pay')}
        </Button>
      </Flex>
    </Form>
  );
};

const phoneRegExp = /^\+?[0-9 \-#()]{6,18}$/;

const senderSchema = (translate, reqPhone) =>
  yup.object().shape({
    name: yup
      .string()
      .required(translate('form.requiredField'))
      .min(5, translate('form.tooShort', { len: 5 })),
    phone: reqPhone
      ? yup
          .string()
          .matches(phoneRegExp, translate('form.invalidPhoneNumber'))
          .required(translate('form.requiredField'))
      : yup.string().matches(phoneRegExp, translate('form.invalidPhoneNumber')),
    email: yup
      .string()
      .required(translate('form.requiredField'))
      .email(translate('form.invalidEmail')),
  });

const addressSchema = (translate, limitAddress) =>
  yup.object().shape({
    street: limitAddress
      ? yup
          .string()
          .required(translate('form.requiredField'))
          .max(35, translate('form.tooLong', { len: 35 }))
      : yup.string().required(translate('form.requiredField')),
    apartment: yup.string(),
    postcode: yup
      .string()
      .required(translate('form.requiredField'))
      .matches(postalCodeRegExp, translate('form.invalidPostcode')),
    city: yup.string().required(translate('form.requiredField')),
    remarks: yup
      .string()
      .optional()
      .max(255, translate('form.tooLong', { len: 255 })),
  });

const SenderFormik = withFormik({
  mapPropsToValues: ({ sender, requireAddress, hasPickup }) => {
    const values = {
      name: sender.name || '',
      phone: sender.phone || '',
      email: sender.email || '',
    };
    if (requireAddress) {
      Object.assign(values, {
        street: sender.street || '',
        apartment: sender.apartment || '',
        postcode: sender.postcode || '',
        city: sender.city || '',
      });
    }
    if (hasPickup) {
      Object.assign(values, {
        remarks: sender.remarks || '',
      });
    }
    return values;
  },

  validationSchema: ({ requireAddress, limitAddress, translate }) => {
    const schema = senderSchema(translate, requireAddress);
    if (requireAddress) {
      return schema.concat(addressSchema(translate, limitAddress));
    }
    return schema;
  },

  handleSubmit: (values, { props: { onSubmit, translate, limitAddress }, ...actions }) => {
    const { setFieldError, setSubmitting } = actions;
    if (limitAddress && streetAndApartment.combine(values).length > 35) {
      if (values.street.length > 30) {
        setFieldError('street', translate('form.tooLong', { len: 30 }));
      }
      if (values.apartment.length > 4) {
        setFieldError('apartment', translate('form.tooLong', { len: 4 }));
      }
      setSubmitting(false);
      return;
    }

    onSubmit(values, actions);
  },

  displayName: 'SenderInputForm',
})(SenderForm);

const mapProfile2Address = profile => {
  const [street, apartment] = streetAndApartment.split(profile.streetAddress);
  const postcode = profile.postNumber;
  const city = profile.postOffice;
  return {
    street,
    apartment,
    postcode,
    city,
  };
};

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const [isProcessing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const basketLoaded = useSelector(state => state.basket && state.basket.basketId);
  const noPayment = useSelector(state => isBasketFree(state.basket));
  const basketRows = useSelector(state => (state.basket.items || []).length);
  const backToShoppingBasket = () => {
    dispatch(locNavigate('/ostoskori'));
  };
  const sender = useSelector(state => {
    const { sender } = state.basket;
    if (sender) {
      return sender;
    }
    const { user } = state.session;
    if (user) {
      let phone = '';
      if (user.phoneNumber) {
        phone = user.phoneNumber;
      }
      return {
        name: user.firstname + ' ' + user.lastname,
        phone,
        email: user.email,
        ...mapProfile2Address(user),
      };
    }
    return {};
  });

  const hasPickup = useSelector(state => {
    const pickupSelected = !!state.basket.pickup;
    const pickupRejected = state.basket.noPickup;
    const anyParcelIncludesPickup = state.basket.items.some(
      item =>
        !item.buySeries && (item.product.pickupIncluded || (item.services || []).some(s => s.override?.pickupIncluded))
    );
    return pickupSelected || (anyParcelIncludesPickup && !pickupRejected);
  });

  const sentAbroad = useSelector(state => state.basket.items.some(i => !isFinlandOrAland(i.country)));
  const requireAddress = sentAbroad || hasPickup;

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const basketId = getBasketId();
      if (basketId && basketLoaded !== basketId) {
        setSubmitting(false);
        return;
      }
      if (isProcessing || basketRows === 0) {
        setSubmitting(false);
        return;
      }
      setProcessing(true);
      try {
        await dispatch(saveSender(values));
      } catch (err) {
        dispatch(showNotification('genericApiError'));
        setProcessing(false);
        setSubmitting(false);
        return;
      }

      if (noPayment) {
        try {
          const order = await dispatch(createOrder({ paymentMethod: 'FREE' }));
          if (order && order.redirectUrl) {
            setOrderId(order.orderId, order.transactionId);
            dispatch(locNavigate(order.redirectUrl));
            return;
          }
        } catch (error) {
          //
        }
      } else {
        dispatch(locNavigate('/payment-method'));
      }

      setProcessing(false);
      setSubmitting(false);
    },
    [dispatch, basketLoaded, noPayment, isProcessing, basketRows]
  );

  dispatch(prefetchPage('/payment-method'));
  // preload user saved payment cards
  const user = useSelector(state => state.session.user);
  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const formProps = { sender, onSubmit, translate, noPayment, requireAddress, limitAddress: sentAbroad, hasPickup };

  return (
    <div>
      <Box>
        <Button onClick={backToShoppingBasket} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backToBasket')}
        </Button>
      </Box>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <SenderFormik key={basketLoaded} {...formProps} />
      </Flex>
    </div>
  );
};
