/** @jsx jsx */
import { jsx, Box, useThemeUI } from 'theme-ui';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import FormField from '../components/FormField';
import Spinner from '../components/Spinner';
import * as api from '../utils/api';
import getLanguage from '../utils/getLanguage';

const formatSuggestion = value => {
  if (typeof value === 'object') {
    const { street, postcode, city } = value;
    return `${street}, ${postcode} ${city}`;
  } else if (typeof value === 'string') {
    return value;
  }
  return '';
};

export default ({ label, country }) => {
  const language = useSelector(state => getLanguage(state));
  const { values, setFieldValue } = useFormikContext();
  const { theme } = useThemeUI();

  const loadAddressSuggestions = useCallback(
    query =>
      (async () => {
        if (query.length < 2) {
          return [];
        }
        return await api.getAddressSuggestions(query, language, country);
      })(),
    [language, country]
  );

  const selectSuggestion = useCallback(
    ({ street, postcode, city }) => {
      setFieldValue('street', street, false);
      setFieldValue('postcode', postcode, false);
      setFieldValue('city', city, false);
    },
    [setFieldValue]
  );

  const onInputChange = useCallback(
    (inputValue, { action }) => {
      if (action === 'input-change') {
        setFieldValue('street', inputValue, false);
      }
    },
    [setFieldValue]
  );

  const selectStyles = {
    container: base => ({ ...base, width: '100%' }),
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      minHeight: 'auto',
      borderWidth: 2,
      borderStyle: 'solid',
      //borderColor: state.isFocused ? theme.colors.primary : theme.colors.border,
      padding: theme.space[2],
      //boxShadow: state.isFocused ? theme.boxShadow.inputFocus : 'none',
      ':hover': {
        borderColor: theme.colors.newBlue,

        //borderColor: state.isFocused ? theme.colors.primary : theme.colors.grayLight,
        //boxShadow: state.isFocused ? theme.boxShadow.inputFocus : theme.boxShadow.inputHover,
      },
    }),
    indicatorsContainer: base => ({ display: 'none' }),
    valueContainer: base => ({ ...base, padding: 0 }),
    input: (base, ...other) => {
      return {
        ...base,
        width: '100%',
        margin: 0,
        padding: 0,
        border: 0,
      };
    },
    menu: base => ({ ...base }),
    option: (base, state) => ({
      ...base,
      color: theme.colors.text,
      backgroundColor: state.isFocused ? theme.colors.blueLighter : 'none',
      ':hover': { backgroundColor: '#E5E8EF', cursor: 'pointer' },
    }),
  };

  return (
    <FormField
      name="street"
      label={label}
      placeholder=""
      as={AsyncSelect}
      styles={selectStyles}
      inputValue={values.street}
      onInputChange={onInputChange}
      tabSelectsValue={true}
      noOptionsMessage={() => null}
      loadOptions={term => loadAddressSuggestions(term, language)}
      getOptionLabel={formatSuggestion}
      isOptionSelected={() => false}
      onChange={selectSuggestion}
      closeMenuOnSelect={false}
      loadingMessage={() => (
        <Box sx={{ py: 2 }}>
          <Spinner />
        </Box>
      )}
    />
  );
};
