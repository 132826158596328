/** @jsx jsx */
import { jsx, Flex, Box, Input, Label } from 'theme-ui';
import { useContext, useState, useEffect, useRef } from 'react';
import { ErrorMessage, Field as FormikField, FormikContext } from 'formik';
import { CaretIcon } from './Icon';

const Field = ({ focus, prefix, error, ...props }) => {
  return (
    <Flex
      sx={{
        position: 'relative',
        zIndex: 1,
        mb: 1,
        width: '100%',
        select: {
          appearance: 'none',
          fontFamily: 'body',
          cursor: 'pointer',
        },
      }}
    >
      {prefix && <Box sx={{ flex: 'none', alignSelf: 'center', mr: 1 }}>{prefix}</Box>}
      <FormikField
        spellCheck={false}
        as={props.as ? props.as : Input}
        {...props}
        focus={focus ? 'true' : 'false'}
        sx={{ variant: error ? 'forms.error' : null }}
      />
    </Flex>
  );
};

const FormField = ({ hidden, helperText, zIndex, lowProfile, prefix, width, trailing, ...props }) => {
  const formikContext = useContext(FormikContext);
  const hasValue = !!formikContext.values[props.name];
  const [focus, setFocus] = useState(hasValue ? true : false);
  const { isValid, isSubmitting, errors, setFieldTouched, handleSubmit } = formikContext;
  const name = props.name;
  const ref = useRef(null);
  useEffect(() => {
    if (isValid || !isSubmitting) return;
    const errorKey = Object.keys(errors)[0];
    if (!isValid && errorKey === name) {
      ref.current && ref.current.focus();
    }
  }, [isSubmitting, isValid, errors, name]);

  return hidden ? null : (
    <Box sx={{ mb: 3, width }}>
      <Label htmlFor={props.name} sx={{ mb: 1 }}>
        {props.label}
      </Label>
      <Box sx={{ position: 'relative' }}>
        <Field
          {...props}
          aria-label={props.label}
          innerRef={ref}
          focus={focus}
          error={props.name in formikContext.errors && formikContext.touched[props.name]}
          prefix={prefix}
          onFocus={e => {
            setFocus(true);
            props.onFocus && props.onFocus(e);
          }}
          onBlur={e => {
            setFieldTouched(props.name, true);
            !e.target.value && setFocus(false);
            props.onBlur && props.onBlur(e);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && !props?.useDefaultAction) {
              e.preventDefault();

              const inputs = Array.from(document.querySelectorAll('input'));
              const currentIndex = inputs.findIndex(input => input.name === props.name);

              if (currentIndex !== -1 && currentIndex < inputs.length - 1) {
                inputs[currentIndex + 1].focus();
              } else {
                inputs[currentIndex].blur();
                handleSubmit();
              }
            }
          }}
        />

        {trailing}
      </Box>
      <Box sx={{ fontSize: 1, lineHeight: 1 }}>
        {helperText && <Box sx={{ mb: 1, color: 'gray' }}>{helperText}</Box>}
        <ErrorMessage name={props.name}>
          {msg => (
            <Box
              sx={{
                color: 'danger',
              }}
            >
              {msg}
            </Box>
          )}
        </ErrorMessage>
      </Box>
    </Box>
  );
};

export default FormField;
