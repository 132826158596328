/** @jsx jsx */
import { jsx, Styled, Textarea, Box, Flex } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import { hideAllNotifications, showNotification, SEVERITY } from '../state/notifications';
import { handleFormValidationError } from '../utils/formError';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form, Field } from 'formik';
import { Container } from '../components';
import CheckboxField from '../components/CheckboxField';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import DropzoneField from '../components/DropzoneField';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import testLogger from '../components/test-logger';
import { deviceDetect } from 'react-device-detect';
import { Select } from '../components/Select';
import * as analytics from '../utils/analytics';
import { InfoIcon } from '../components/Icon';

const claimDamagesSchema = (translate) =>
  yup.object().shape({
    damageSustained: yup
      .string()
      .oneOf(
        [
          translate('claimDamagesForm.damages.breakage'),
          translate('claimDamagesForm.damages.loss'),
          translate('claimDamagesForm.damages.delay'),
          translate('claimDamagesForm.damages.reduction'),
          translate('claimDamagesForm.damages.other'),
        ],
        translate('claimDamagesForm.message.invalidDamage')
      )
      .required(translate('claimDamagesForm.message.requiredField')),
    consignmentNumber: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    dispatchDateAndTerminal: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    receivingDateAndDestination: yup.string().when('damageSustained', {
      is: (damage) => damage !== translate('claimDamagesForm.damages.loss'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    from: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    to: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    damagedGoods: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.breakage'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    weightOfDamagedGoods: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.breakage'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    lostContents: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.loss'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    lostPackaging: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.loss'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    lostAllOrPartial: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.loss'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    weightOfLostGoods: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.loss'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    damageDetected: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.breakage'),
      then: yup
        .string()
        .oneOf(
          [translate('claimDamagesForm.detections.received'), translate('claimDamagesForm.detections.opened')],
          translate('claimDamagesForm.message.invalidDetection')
        )
        .required(translate('claimDamagesForm.message.requiredField')),
    }),
    complaintFiledThrough: yup.string().when('damageSustained', {
      is: (damage) =>
        [translate('claimDamagesForm.damages.breakage'), translate('claimDamagesForm.damages.delay')].includes(damage),
      then: yup
        .string()
        .oneOf(
          [
            '',
            translate('claimDamagesForm.complaints.entryInDeliveryList'),
            translate('claimDamagesForm.complaints.writtenComplaint'),
          ],
          translate('claimDamagesForm.message.invalidComplaint')
        ),
    }),
    whenDate: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    damageVerified: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.breakage'),
      then: yup
        .string()
        .oneOf(
          [translate('claimDamagesForm.verifications.yes'), translate('claimDamagesForm.verifications.no')],
          translate('claimDamagesForm.message.invalidVerification')
        )
        .required(translate('claimDamagesForm.message.requiredField')),
    }),
    goodsNow: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.breakage'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    damageDescription: yup.string().when('damageSustained', {
      is: (damage) => damage !== translate('claimDamagesForm.damages.loss'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    value: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    goodsStatus: yup.string().when('damageSustained', {
      is: (damage) =>
        [translate('claimDamagesForm.damages.breakage'), translate('claimDamagesForm.damages.loss')].includes(damage),
      then: yup
        .string()
        .oneOf(
          [
            translate('claimDamagesForm.goodsStatuses.packaged'),
            translate('claimDamagesForm.goodsStatuses.unpackaged'),
          ],
          translate('claimDamagesForm.message.invalidGoodsStatus')
        )
        .required(translate('claimDamagesForm.message.requiredField')),
    }),
    goodsStatus2: yup.string().when('damageSustained', {
      is: (damage) =>
        [translate('claimDamagesForm.damages.breakage'), translate('claimDamagesForm.damages.loss')].includes(damage),
      then: yup
        .string()
        .oneOf(
          [translate('claimDamagesForm.goodsStatuses.new'), translate('claimDamagesForm.goodsStatuses.used')],
          translate('claimDamagesForm.message.invalidGoodsStatus')
        )
        .required(translate('claimDamagesForm.message.requiredField')),
    }),
    packagingDescription: yup.string().when('damageSustained', {
      is: (damage) => damage === translate('claimDamagesForm.damages.breakage'),
      then: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    }),
    claimedBy: yup
      .string()
      .oneOf(
        [
          translate('claimDamagesForm.claims.consignor'),
          translate('claimDamagesForm.claims.consignee'),
          translate('claimDamagesForm.claims.other'),
        ],
        translate('claimDamagesForm.message.invalidClaim')
      )
      .required(translate('claimDamagesForm.message.requiredField')),
    contactPerson: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    contactAddress: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    contactPhone: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    email: yup.string().email(translate('claimDamagesForm.message.invalidEmail')),
    contactCompany: yup.string(),
    // charges: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    bankAccount: yup.string().required(translate('claimDamagesForm.message.requiredField')),
    insuranceCompany: yup.string(),
    accuracy: yup
      .bool()
      .oneOf([true], translate('claimDamagesForm.message.requiredField'))
      .required(translate('claimDamagesForm.message.requiredField')),
    attachment: yup
      .array()
      .of(yup.mixed())
      .max(10, translate('feedback.message.tooManyFiles', { files: 10 }))
      .ensure(),
    /**.test(
        'tooBig',
        translate('feedback.message.sizeLimit', { size: '10MB' }),
        yupMaxEmailFileSizeText
      )*/ recaptcha: yup.string().required(),
  });

const ClaimDamagesForm = ({ translate, handleSubmit, isSubmitting, setFieldValue, values, errors, nocaptcha }) => {
  const isBreakage = values.damageSustained === translate('claimDamagesForm.damages.breakage');
  const isLoss = values.damageSustained === translate('claimDamagesForm.damages.loss');
  const isDelay = values.damageSustained === translate('claimDamagesForm.damages.delay');
  const isOther = values.damageSustained === translate('claimDamagesForm.damages.other');

  return (
    <Form>
      <FormField as={Select} name="damageSustained" label={translate('claimDamagesForm.damageSustained')}>
        <option value={translate('claimDamagesForm.damages.breakage')}>
          {translate('claimDamagesForm.damages.breakage')}
        </option>
        <option value={translate('claimDamagesForm.damages.loss')}>{translate('claimDamagesForm.damages.loss')}</option>
        <option value={translate('claimDamagesForm.damages.delay')}>
          {translate('claimDamagesForm.damages.delay')}
        </option>
        {/*
        <option value={translate('claimDamagesForm.damages.reduction')}>
          {translate('claimDamagesForm.damages.reduction')}
        </option>
        */}
        <option value={translate('claimDamagesForm.damages.other')}>
          {translate('claimDamagesForm.damages.other')}
        </option>
      </FormField>
      <FormField name="consignmentNumber" label={translate('claimDamagesForm.consignmentNumber')} />
      <FormField name="dispatchDateAndTerminal" label={translate('claimDamagesForm.dispatchDateAndTerminal')} />
      {!isLoss && (
        <FormField
          name="receivingDateAndDestination"
          label={translate('claimDamagesForm.receivingDateAndDestination')}
        />
      )}
      <FormField name="from" label={translate('claimDamagesForm.from')} />
      <FormField name="to" label={translate('claimDamagesForm.to')} />
      {isBreakage && (
        <>
          <FormField name="damagedGoods" label={translate('claimDamagesForm.damagedGoods')} />
          <FormField name="weightOfDamagedGoods" label={translate('claimDamagesForm.weightOfDamagedGoods')} />
          <FormField as={Select} name="damageDetected" label={translate('claimDamagesForm.damageDetected')}>
            <option value={''}>{translate('claimDamagesForm.defaultSelection')}</option>
            <option value={translate('claimDamagesForm.detections.received')}>
              {translate('claimDamagesForm.detections.received')}
            </option>
            <option value={translate('claimDamagesForm.detections.opened')}>
              {translate('claimDamagesForm.detections.opened')}
            </option>
          </FormField>
        </>
      )}
      {isLoss && (
        <>
          <FormField name="lostContents" label={translate('claimDamagesForm.lostContents')} />
          <FormField name="lostPackaging" label={translate('claimDamagesForm.lostPackaging')} />
          <FormField name="lostAllOrPartial" label={translate('claimDamagesForm.lostAllOrPartial')} />
          <FormField name="weightOfLostGoods" label={translate('claimDamagesForm.weightOfLostGoods')} />
        </>
      )}
      {(isBreakage || isDelay) && (
        <>
          <FormField
            as={Select}
            name="complaintFiledThrough"
            label={translate('claimDamagesForm.complaintFiledThrough')}
          >
            <option value={''}>{translate('claimDamagesForm.defaultSelection')}</option>
            <option value={translate('claimDamagesForm.complaints.entryInDeliveryList')}>
              {translate('claimDamagesForm.complaints.entryInDeliveryList')}
            </option>
            <option value={translate('claimDamagesForm.complaints.writtenComplaint')}>
              {translate('claimDamagesForm.complaints.writtenComplaint')}
            </option>
          </FormField>
        </>
      )}
      <FormField name="whenDate" label={translate('claimDamagesForm.whenDate')} />
      {isBreakage && (
        <>
          <FormField as={Select} name="damageVerified" label={translate('claimDamagesForm.damageVerified')}>
            <option value={''}>{translate('claimDamagesForm.defaultSelection')}</option>
            <option value={translate('claimDamagesForm.verifications.yes')}>
              {translate('claimDamagesForm.verifications.yes')}
            </option>
            <option value={translate('claimDamagesForm.verifications.no')}>
              {translate('claimDamagesForm.verifications.no')}
            </option>
          </FormField>
          <FormField name="goodsNow" label={translate('claimDamagesForm.goodsNow')} />
        </>
      )}
      {!isLoss && (
        <>
          <FormField
            name="damageDescription"
            as={Textarea}
            rows={4}
            label={translate('claimDamagesForm.damageDescription')}
          />
          <FormField name="value" label={translate('claimDamagesForm.value')} />
        </>
      )}
      {(isBreakage || isLoss) && (
        <>
          <FormField as={Select} name="goodsStatus" label={translate('claimDamagesForm.goodsStatus')}>
            <option value={''}>{translate('claimDamagesForm.defaultSelection')}</option>
            <option value={translate('claimDamagesForm.goodsStatuses.packaged')}>
              {translate('claimDamagesForm.goodsStatuses.packaged')}
            </option>
            <option value={translate('claimDamagesForm.goodsStatuses.unpackaged')}>
              {translate('claimDamagesForm.goodsStatuses.unpackaged')}
            </option>
          </FormField>
          <FormField as={Select} name="goodsStatus2" label={translate('claimDamagesForm.goodsStatus')}>
            <option value={''}>{translate('claimDamagesForm.defaultSelection')}</option>
            <option value={translate('claimDamagesForm.goodsStatuses.new')}>
              {translate('claimDamagesForm.goodsStatuses.new')}
            </option>
            <option value={translate('claimDamagesForm.goodsStatuses.used')}>
              {translate('claimDamagesForm.goodsStatuses.used')}
            </option>
          </FormField>
        </>
      )}
      {isBreakage && (
        <FormField
          name="packagingDescription"
          as={Textarea}
          rows={4}
          label={translate('claimDamagesForm.packagingDescription')}
        />
      )}
      <FormField as={Select} name="claimedBy" label={translate('claimDamagesForm.claimedBy')}>
        <option value={translate('claimDamagesForm.claims.consignor')}>
          {translate('claimDamagesForm.claims.consignor')}
        </option>
        <option value={translate('claimDamagesForm.claims.consignee')}>
          {translate('claimDamagesForm.claims.consignee')}
        </option>
        <option value={translate('claimDamagesForm.claims.other')}>{translate('claimDamagesForm.claims.other')}</option>
      </FormField>
      <FormField name="contactPerson" label={translate('claimDamagesForm.contactPerson')} />
      <FormField name="contactAddress" as={Textarea} label={translate('claimDamagesForm.contactAddress')} />
      <FormField name="contactPhone" label={translate('claimDamagesForm.contactPhone')} />
      <FormField name="email" label={translate('claimDamagesForm.email')} />
      <FormField name="contactCompany" as={Textarea} label={translate('claimDamagesForm.contactCompany')} />
      {isLoss && <FormField name="value" label={translate('claimDamagesForm.value')} />}
      {/* <FormField name="charges" label={translate('claimDamagesForm.charges')} /> */}
      <FormField name="bankAccount" label={translate('claimDamagesForm.bankAccount')} />
      <FormField name="insuranceCompany" label={translate('claimDamagesForm.insuranceCompany')} />
      <Field
        component={CheckboxField}
        name="accuracy"
        id="accuracy"
        label={translate('claimDamagesForm.accuracyDetails')}
        sx={{ mb: 3 }}
        error={errors.accuracy}
      />
      <DropzoneField
        name="attachment"
        label={translate('claimDamagesForm.attachment')}
        placeholder={translate('claimDamagesForm.dropzoneText')}
        clearButtonText={translate('claimDamagesForm.clear')}
        zoneActiveText={translate('claimDamagesForm.dropzoneActiveText')}
      />
      <RecaptchaButton
        buttonText="claimDamagesForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
        errors={errors}
      />
    </Form>
  );
};

const ClaimDamagesFormik = withFormik({
  mapPropsToValues: ({ vo, translate }) =>
    Object.assign({
      damageSustained: vo.damageSustained || translate('claimDamagesForm.damages.breakage'),
      consignmentNumber: vo.consignmentNumber || '',
      dispatchDateAndTerminal: vo.dispatchDateAndTerminal || '',
      receivingDateAndDestination: vo.receivingDateAndDestination || '',
      from: vo.from || '',
      to: vo.to || '',
      damagedGoods: vo.damagedGoods || '',
      weightOfDamagedGoods: vo.weightOfDamagedGoods || '',
      lostContents: vo.lostContents || '',
      lostPackaging: vo.lostPackaging || '',
      lostAllOrPartial: vo.lostAllOrPartial || '',
      weightOfLostGoods: vo.weightOfLostGoods || '',
      damageDetected: vo.damageDetected || '',
      complaintFiledThrough: vo.complaintFiledThrough || '',
      whenDate: vo.whenDate || '',
      damageVerified: vo.damageVerified || '',
      goodsNow: vo.goodsNow || '',
      damageDescription: vo.damageDescription || '',
      value: vo.value || '',
      goodsStatus: vo.goodsStatus || '',
      goodsStatus2: vo.goodsStatus2 || '',
      packagingDescription: vo.packagingDescription || '',
      claimedBy: vo.claimedBy || translate('claimDamagesForm.claims.consignor'),
      contactPerson: vo.contactPerson || '',
      contactAddress: vo.contactAddress || '',
      contactPhone: vo.contactPhone || '',
      email: vo.email || '',
      // charges: vo.charges || '',
      bankAccount: vo.bankAccount || '',
      insuranceCompany: vo.insuranceCompany || '',
      accuracy: vo.accuracy || false,
      attachment: vo.attachment || [],
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return claimDamagesSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'ClaimDamagesInputForm',
})(ClaimDamagesForm);

const ClaimDamagesPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector((state) => state.localize));
  const locale = useSelector((state) => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendClaimDamagesForm({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: {
              claimDamages: true,
              // message: translate('claimDamagesForm.message.success'),
            },
          })
        );
        //dispatch(showNotification('claimDamagesForm.message.success', SEVERITY.INFO));
        setSubmitting(false);
        setShowSpinner(false);
      } catch (error) {
        testLogger.log('sendClaimDamagesForm:FAILED ' + error + ', ' + JSON.stringify(deviceDetect()));
        setSubmitting(false);
        setShowSpinner(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(showNotification('claimDamagesForm.message.failed', SEVERITY.ERROR));
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout title={translate('claimDamagesForm.title')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('claimDamagesForm.title')}</Styled.h1>
        <Styled.p>{translate('claimDamagesForm.description1')}</Styled.p>
        <Styled.h2 sx={{ mb: 4 }}>{translate('claimDamagesForm.subtitle')}</Styled.h2>
        <Styled.ol sx={{ color: 'black' }}>
          <li sx={{ mt: 3 }}>{translate('claimDamagesForm.description2')}</li>
          <li sx={{ mt: 3 }}>{translate('claimDamagesForm.description3')}</li>
          <li sx={{ mt: 3 }}>{translate('claimDamagesForm.description4')}</li>
          <li sx={{ mt: 3 }}>{translate('claimDamagesForm.description5')}</li>
        </Styled.ol>
        <Styled.ul sx={{ mt: 3, ml: 3, color: 'black' }}>
          <li>{translate('claimDamagesForm.description6')}</li>
          <li>{translate('claimDamagesForm.description7')}</li>
          <li>{translate('claimDamagesForm.description8')}</li>
          <li>{translate('claimDamagesForm.description9')}</li>
        </Styled.ul>

        <Box sx={{ width: '100%', backgroundColor: 'blueLighter', py: '24px', px: 4, my: 4 }}>
          <Flex sx={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
            <div>
              <InfoIcon />
            </div>
            <span sx={{ color: 'black' }}>{translate('claimDamagesForm.description11')}</span>
          </Flex>
        </Box>
        <div sx={{ py: 3 }}></div>
        <ClaimDamagesFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default ClaimDamagesPage;
