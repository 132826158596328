/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from 'theme-ui';
import { rgba } from 'polished';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import debounce from 'lodash/debounce';

import { setViewedAreas } from '../state/servicePoints';
import { isBrowser } from '../utils';
import mapScriptLoader from '../utils/mapScriptLoader';
import theme from '../gatsby-plugin-theme-ui';
import createClusterTheme from './clusteringTheme';
import createMarkerIcon, { createUrlDomIcon } from './markerIcon';

const DEF_LATITUDE = 62.48;
const DEF_LONGITUDE = 25.69;
const DEF_ZOOM = 6;
const MAX_ZOOM_OUT = 6.1;
const MAX_TOP = 70.1;
const MAX_LEFT = 19.1;
const MAX_BOTTOM = 59.5;
const MAX_RIGHT = 31.6;
const FOCUS_ZOOM = 15;
const FOCUS_ZOOM_MULTIPLIER = 0.3;
const VIEW_AREA_RESIZE = 0.06;
const PADDING_BREAKPOINTS = [false, false, true]; // Breakpoints for responsive layout
const SELECTED_MARKER_URL = '/map-marker-selected.svg';
const FOCUS_POINT_MARKER_URL = '/map-focus-point.svg';

/**
 * Wraps HERE map into a component
 * https://developer.here.com/documentation/maps/dev_guide/topics/overview.html
 */
class Map extends React.Component {
  platform = null; // H.service.Platform
  map = null; // H.Map
  ui = null; // H.ui.UI
  selectedMarkerIcon = null; // H.map.DomIcon
  focusMarkerIcon = null; // H.map.DomIcon
  viewedAreas = []; // Array<H.geo.Rect>
  dataPoints = []; // Array<H.clustering.DataPoint>

  state = {
    mapCreated: false,
    dragging: false,
    singleTouchDrag: false,
  };

  componentDidMount() {
    mapScriptLoader(() => {
      try {
        this.createMap();
        this.setMarkers(this.props.offices);
      } catch (error) {
        console.error('Problem in creating map:', error);
      }
    });
  }

  getSnapshotBeforeUpdate(prevProps) {
    const snapshot = {
      officesChanged: false,
      selectedOfficeChanged: false,
      officesFocusGroupChanged: false,
    };
    // check if selected office has changed
    const currentCode = this.props.selectedOffice && this.props.selectedOffice.officeCode;
    const prevCode = prevProps.selectedOffice && prevProps.selectedOffice.officeCode;

    if (currentCode !== prevCode) {
      snapshot.selectedOfficeChanged = true;
    }

    if (this.props.offices) {
      snapshot.officesChanged = this.officeListChanged(this.props.offices, prevProps.offices);
    }

    if (this.props.officesFocusGroup) {
      snapshot.officesFocusGroupChanged = this.officeListChanged(
        this.props.officesFocusGroup,
        prevProps.officesFocusGroup
      );
    }

    return snapshot;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mapCreated) {
      if (snapshot.officesChanged) {
        this.setMarkers();
      }
      if (snapshot.selectedOfficeChanged) {
        this.setSelectedMarker();
        this.props.focus && this.focusOnSelectedMarker();
      }
      if (snapshot.officesFocusGroupChanged && this.props.focus) {
        this.focusOnMarkerGroup();
        this.onViewChanged();
      }
      if (prevProps.focusPoint !== this.props.focusPoint) {
        this.setFocusPoint();
      }
    }
  }

  componentWillUnmount() {
    // H.geo.Rect objects cannot be directly stored
    const reduxSafeAreas = this.viewedAreas.map(area => ({
      left: area.getLeft(),
      right: area.getRight(),
      top: area.getTop(),
      bottom: area.getBottom(),
    }));
    this.props.setViewedAreas(reduxSafeAreas);
  }

  officeListChanged(offices, prevOffices) {
    let changed = false;
    if (offices.length === prevOffices.length) {
      offices.forEach(({ officeCode }) => {
        if (!prevOffices.find(office => office.officeCode === officeCode)) {
          changed = true;
        }
      });
    } else {
      changed = true;
    }
    return changed;
  }

  createMap = () => {
    const HERE = isBrowser && window.H;
    const mapDom = document.getElementById('here-map');
    // Heading and tilt behaviors are disabled
    const enabledBehaviors =
      HERE.mapevents.Behavior.Feature.PANNING |
      HERE.mapevents.Behavior.Feature.PINCH_ZOOM |
      HERE.mapevents.Behavior.Feature.WHEEL_ZOOM |
      HERE.mapevents.Behavior.Feature.DBL_TAP_ZOOM |
      HERE.mapevents.Behavior.Feature.FRACTIONAL_ZOOM;

    this.platform = new HERE.service.Platform({
      apikey: process.env.HERE_API_KEY,
    });

    // Obtain the default map types from the platform object
    const defaultLayers = this.platform.createDefaultLayers();

    // Instantiate (and display) a map object
    this.map = new HERE.Map(mapDom, defaultLayers.vector.normal.map, {
      zoom: DEF_ZOOM,
      center: { lat: DEF_LATITUDE, lng: DEF_LONGITUDE },
      pixelRatio: (window && window.devicePixelRatio) || 1,
    });

    // Add a resize listener to make sure that the map occupies
    // the whole container and padding is correct

    const viewport = this.map.getViewPort();
    const setPadding = () =>
      viewport.setPadding(0, 0, 0, this.usePadding() && this.props.paddingLeft ? this.props.paddingLeft : 0);
    setPadding();

    isBrowser &&
      window.addEventListener('resize', () => {
        viewport.resize();
        setPadding();
      });

    // Add zoom pan etc. controls

    const behavior = new HERE.mapevents.Behavior(new HERE.mapevents.MapEvents(this.map), {
      kinetics: { power: 0, duration: 0 },
      enabled: enabledBehaviors,
    });
    this.ui = new HERE.ui.UI(this.map, { locale: 'fi-FI' });
    const zoomControl = new HERE.ui.ZoomControl({
      alignment: HERE.ui.LayoutAlignment.BOTTOM_RIGHT,
    });
    this.ui.addControl('zoom', zoomControl);
    this.ui.addControl('scalebar', new HERE.ui.ScaleBar({ alignment: HERE.ui.LayoutAlignment.BOTTOM_RIGHT }));

    // Load previously viewed areas and transform them to H.geo.Rect objects

    this.viewedAreas = this.props.viewedAreas.map(
      area => new HERE.geo.Rect(area.top, area.left, area.bottom, area.right)
    );

    // Trigger view change function on user input

    this.map.addEventListener('wheel', this.onViewChanged);
    this.map.addEventListener('dragend', this.onViewChanged);
    this.map.addEventListener('mapviewchange', this.onViewChanged);

    // Add Google maps like controls

    let startY,
      endY = 0;
    this.map.addEventListener('dragstart', e => {
      if (e.currentPointer.type === 'touch') {
        if (e.pointers.length < 2) {
          startY = e.currentPointer.viewportY;
          behavior.disable();
          this.setState({ singleTouchDrag: true });
        }
      }
    });

    this.map.addEventListener('drag', e => {
      if (e.currentPointer.type === 'touch' && e.pointers.length < 2) {
        endY = e.currentPointer.viewportY;
        isBrowser && window.scrollBy(0, startY - endY);
      }
    });

    this.map.addEventListener('dragend', () => {
      behavior.enable(enabledBehaviors);
      if (this.state.singleTouchDrag) this.setState({ singleTouchDrag: false });
    });

    // Initialize Icon for selected office

    this.selectedMarkerIcon = createMarkerIcon(HERE, SELECTED_MARKER_URL);
    this.focusMarkerIcon = createUrlDomIcon(HERE, FOCUS_POINT_MARKER_URL);

    // Add bounds where user can look at (Finland) and how far can zoom out

    const bounds = new HERE.geo.Rect(MAX_TOP, MAX_LEFT, MAX_BOTTOM, MAX_RIGHT);

    this.map.getViewModel().addEventListener('sync', () => {
      let center = this.map.getCenter();

      if (this.map.getZoom() < MAX_ZOOM_OUT) {
        this.map.setZoom(MAX_ZOOM_OUT);
      }

      if (!bounds.containsPoint(center)) {
        if (center.lat > bounds.getTop()) {
          center.lat = bounds.getTop();
        } else if (center.lat < bounds.getBottom()) {
          center.lat = bounds.getBottom();
        }
        if (center.lng < bounds.getLeft()) {
          center.lng = bounds.getLeft();
        } else if (center.lng > bounds.getRight()) {
          center.lng = bounds.getRight();
        }
        this.map.setCenter(center);
      }
    });

    // Initialize a clustering provider for clusterizing office markers

    this.clusteredDataProvider = new HERE.clustering.Provider([], {
      clusteringOptions: {
        // Maximum radius of the neighbourhood
        eps: 40,
        // minimum weight of points required to form a cluster
        minWeight: 3,
        strategy: HERE.clustering.Provider.Strategy.DYNAMICGRID,
      },
      theme: createClusterTheme(HERE),
    });
    // Event listener is attached to cluster provider not individual markers
    const onMarkerClick = event => {
      const markerData = event.target.getData();
      if (!markerData) {
        // cluster
        const currentZoom = this.map.getZoom();
        this.map.getViewModel().setLookAtData(
          {
            position: event.target.getGeometry(),
            zoom: 1.2 * currentZoom,
          },
          false
        );
      }
      if (this.props.onSelectOffice && markerData) {
        this.props.onSelectOffice(this.props.offices.find(office => office.officeCode === markerData.officeCode));
      }
    };
    this.clusteredDataProvider.addEventListener('tap', onMarkerClick, false);
    // Create a layer that will consume objects from clustering provider
    const clusteringLayer = new HERE.map.layer.ObjectLayer(this.clusteredDataProvider);
    // zIndex is set to 0 so selected marker appears at top
    this.map.addLayer(clusteringLayer, 0);

    // Provide user location

    const searchUserLocation = onCoordinatesFound => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.map.setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            this.map.setZoom(FOCUS_ZOOM, true);
            onCoordinatesFound(position.coords.latitude, position.coords.longitude);
          },
          e => console.warn('searchUserLocation failed:', e),
          { maximumAge: 10000, timeout: 6000 }
        );
      }
    };

    const searchAddressByCoordinates = (lat, long, onSuccess) => {
      const geocoder = this.platform.getGeocodingService();
      geocoder.reverseGeocode(
        {
          prox: `${lat},${long}`,
          mode: 'retrieveAddress',
          maxresults: 1,
        },
        onSuccess,
        e => console.warn('searchAddressByCoordinates failed:', e)
      );
    };

    if (this.props.onAddressFound) {
      searchUserLocation((lat, lng) =>
        searchAddressByCoordinates(lat, lng, result => {
          this.props.onAddressFound && this.props.onAddressFound(result.Response.View[0].Result[0].Location.Address);
        })
      );
    }

    this.setState({ mapCreated: true });
  };

  setMarkers() {
    const HERE = isBrowser && window.H;
    const { selectedOffice } = this.props;
    const selectedOfficeCode = selectedOffice && selectedOffice.officeCode;
    const weight = 1; // weights are summed up to display number of markers in cluster
    this.dataPoints = this.props.offices
      .filter(o => o.latitude && o.longitude)
      .map(
        office =>
          new HERE.clustering.DataPoint(office.latitude, office.longitude, weight, {
            officeCode: office.officeCode,
            isOwn: ['STATION', 'AGENT'].includes(office.officeType),
            isSelected: selectedOfficeCode === office.officeCode,
          })
      );
    this.clusteredDataProvider.setDataPoints(this.dataPoints);
  }

  setSelectedMarker() {
    const HERE = window.H;
    const { selectedOffice } = this.props;

    // Remove old marker
    this.selectedMarker && this.map.removeObject(this.selectedMarker);
    this.selectedMarker = null;

    // Create and set new marker
    if (selectedOffice && selectedOffice.latitude && selectedOffice.longitude) {
      this.selectedMarker = new HERE.map.DomMarker(
        {
          lat: selectedOffice.latitude,
          lng: selectedOffice.longitude,
        },
        {
          icon: this.selectedMarkerIcon,
        }
      );
      this.map.addObject(this.selectedMarker);
    }
  }

  setFocusPoint() {
    const HERE = window.H;

    // Remove old marker
    this.focusMarker && this.map.removeObject(this.focusMarker);
    this.focusMarker = null;

    const { focusPoint } = this.props;

    // Create and set new marker
    if (focusPoint && focusPoint.lat && focusPoint.lng) {
      this.focusMarker = new HERE.map.DomMarker(
        {
          lat: focusPoint.lat,
          lng: focusPoint.lng,
        },
        {
          icon: this.focusMarkerIcon,
        }
      );
      this.map.addObject(this.focusMarker);
    }
  }

  onViewChanged = debounce(() => {
    const HERE = isBrowser && window.H;
    const viewArea = this.map
      .getViewModel()
      .getLookAtData()
      .bounds.getBoundingBox();

    if (this.props.onViewChanged) {
      this.props.onViewChanged({
        left: viewArea.getLeft(),
        right: viewArea.getRight(),
        top: viewArea.getTop(),
        bottom: viewArea.getBottom(),
      });
    }

    let isNewArea = true;
    this.viewedAreas.forEach(area => {
      if (area.containsRect(viewArea)) {
        isNewArea = false;
      }
    });

    if (isNewArea) {
      const biggerArea = new HERE.geo.Rect(
        viewArea.getTop() + VIEW_AREA_RESIZE,
        viewArea.getLeft() - VIEW_AREA_RESIZE,
        viewArea.getBottom() - VIEW_AREA_RESIZE,
        viewArea.getRight() + VIEW_AREA_RESIZE
      );
      this.viewedAreas = [...this.viewedAreas, biggerArea];

      if (this.props.onNewView) {
        this.props.onNewView({
          left: biggerArea.getLeft(),
          right: biggerArea.getRight(),
          top: biggerArea.getTop(),
          bottom: biggerArea.getBottom(),
        });
      }
    }
  }, 1000);

  focusOnSelectedMarker() {
    if (this.selectedMarker) {
      const currentZoom = this.map.getZoom();
      this.map.getViewModel().setLookAtData(
        {
          bounds: this.selectedMarker.getGeometry().getBoundingBox(),
          zoom: currentZoom > FOCUS_ZOOM ? currentZoom : FOCUS_ZOOM,
        },
        false
      );
    }
  }

  focusOnMarkerGroup() {
    const { officesFocusGroup } = this.props;
    if (!(Array.isArray(officesFocusGroup) && officesFocusGroup.length > 0)) return;
    const validOffices = officesFocusGroup.filter(o => o.latitude && o.longitude);
    if (validOffices.length === 0) return;

    const HERE = isBrowser && window.H;
    const geoPoints = validOffices.map(office => ({
      lat: office.latitude,
      lng: office.longitude,
    }));
    const multiPoint = new HERE.geo.MultiPoint(geoPoints);
    const groupBounds = multiPoint.getBoundingBox();
    const widthMod = Math.max(0.01, groupBounds.getWidth()) * FOCUS_ZOOM_MULTIPLIER;
    const heightMod = Math.max(0.01, groupBounds.getHeight()) * FOCUS_ZOOM_MULTIPLIER; // show atleast 0.01 degrees latitude
    const lookAtBoundingBox = new HERE.geo.Rect(
      groupBounds.getTop() + heightMod,
      groupBounds.getLeft() - widthMod,
      groupBounds.getBottom() - heightMod,
      groupBounds.getRight() + widthMod
    );
    this.map.getViewModel().setLookAtData({ bounds: lookAtBoundingBox }, false);
  }

  usePadding = () => {
    if (!theme.breakpoints || !isBrowser) return false;

    let themeIndex = theme.breakpoints.findIndex(point => {
      if (point.substring(point.length - 2) === 'em') {
        const fontSize = getComputedStyle(document.querySelector('body'))['font-size'];
        return window.innerWidth < parseFloat(fontSize) * parseFloat(point);
      } else {
        return window.innerWidth < parseFloat(point);
      }
    });
    const listLen = PADDING_BREAKPOINTS.length;
    return themeIndex >= 0 && themeIndex < listLen ? PADDING_BREAKPOINTS[themeIndex] : PADDING_BREAKPOINTS[listLen - 1];
  };

  render() {
    const { translate, height, paddingLeft } = this.props;
    const padding = paddingLeft ? `${paddingLeft}px` : '0';

    return (
      <div sx={{ minHeight: ['50vh', null, `${height}vh`], position: 'relative' }} aria-hidden={true}>
        <link
          id="here-style-link"
          rel="stylesheet"
          type="text/css"
          href="https://js.api.here.com/v3/3.1/mapsjs-ui.css"
        />
        <div
          id="here-map"
          sx={{
            width: '100%',
            height: '100%',
            background: 'grey',
            position: 'absolute',
          }}
        />
        <div
          sx={{
            position: 'absolute',
            background: rgba(60, 60, 60, 0.5),
            pl: this.usePadding() ? ['0', null, padding] : '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            opacity: this.state.singleTouchDrag ? '1' : '0',
            transitionProperty: 'opacity',
            transitionDuration: '0.3s',
            transitionTimingFunction: 'ease-out',
            pointerEvents: 'none',
          }}
        >
          <h1 sx={{ color: 'white', margin: 'auto', textAlign: 'center' }}>{translate('map.moveWithTwoFingers')}</h1>
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  offices: PropTypes.array,
  officesFocusGroup: PropTypes.array,
  selectedOffice: PropTypes.object,
  onSelectOffice: PropTypes.func,
  onViewChanged: PropTypes.func,
  onNewView: PropTypes.func,
  focus: PropTypes.bool,
  onAddressFound: PropTypes.func,
  height: PropTypes.number,
  paddingLeft: PropTypes.number,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  viewedAreas: state.servicePoints.viewedAreas,
  focusPoint: state.servicePoints.focus,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setViewedAreas,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Map);
