/** @jsx jsx */
import { jsx, Box, Flex, Container, Styled, useThemeUI } from 'theme-ui';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Cookies from 'js-cookie';
import Button from './Button';
import Switch from './Switch';
import * as analytics from '../utils/analytics';
import { isBrowser } from '../utils';

const CenteredBottomOverlay = ({ children }) => {
  return (
    <Box
      sx={{
        maxHeight: '100%',
        position: 'fixed',
        zIndex: '999',
        bg: 'blueLighter',
        color: 'text',
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'scroll',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: ['column', null, 'row'],
          justifyContent: 'center',
          py: [3, 4],
          fontSize: [1, 2],
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

const cookieName = 'CookieConsentV2';
const necessaryCategories = ['YES'];
const allCategories = [...necessaryCategories, 'TOI', 'ANA', 'KOH'];

// delete cookie in browser console:
// document.cookie = 'CookieConsentV2=; max-age=0';

const getDomainForCookie = () => {
  if (window.location.hostname.endsWith('cloudfront.net')) {
    return window.location.hostname;
  }
  return window.location.hostname
    .split('.')
    .slice(-2)
    .join('.');
};

const loadVine = agreedCategories => {
  if (process.env.GATSBY_ACTIVE_ENV !== 'production') {
    return;
  }
  if (!agreedCategories.includes('KOH')) {
    return;
  }
  if (isBrowser && !window.VineTrack) {
    if (process.env.GATSBY_ACTIVE_ENV !== 'production') {
      console.log('Load vine');
    }
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://vine.eu/track/t.js?sg=312971930';
    document.getElementsByTagName('head')[0].appendChild(s);
  }
};

const setConsentCookie = agreedCategories => {
  Cookies.set(cookieName, agreedCategories.join(','), {
    domain: getDomainForCookie(),
    expires: 365,
  });
  analytics.setCookieConsent(agreedCategories, true);
  loadVine(agreedCategories);
};

const AcceptAllOverlay = ({ setRefresh, showCategories }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const acceptAll = useCallback(() => {
    Cookies.set(cookieName, allCategories.join(','), {
      domain: getDomainForCookie(),
      expires: 365,
    });
    analytics.setCookieConsent(allCategories, true);
    loadVine(allCategories);
    setRefresh(true);
  }, [setRefresh]);

  const acceptNecessary = useCallback(() => {
    Cookies.set(cookieName, necessaryCategories.join(','), {
      domain: getDomainForCookie(),
      expires: 365,
    });
    analytics.setCookieConsent(necessaryCategories, true);
    loadVine(necessaryCategories);
    setRefresh(true);
  }, [setRefresh]);

  return (
    <CenteredBottomOverlay>
      <Box>
        <Styled.strong sx={{ display: 'block', mb: 2 }}>{translate('cookieConsent.title')}</Styled.strong>
        <Box
          dangerouslySetInnerHTML={{
            __html: translate('cookieConsent.content'),
          }}
          sx={{ mb: [3, 3, 0] }}
        />
      </Box>
      <Flex
        sx={{
          flex: 'none',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          pl: [0, 0, 6],
        }}
      >
        <Button onClick={acceptAll}>{translate('cookieConsent.acceptAll')}</Button>
        <Button variant="secondary" onClick={acceptNecessary} sx={{ mt: 2 }}>
          {translate('cookieConsent.acceptNecessary')}
        </Button>
        <Button variant="secondary" sx={{ mt: 2 }} onClick={showCategories}>
          {translate('cookieConsent.select')}
        </Button>
      </Flex>
    </CenteredBottomOverlay>
  );
};

export const SelectCategories = ({ setRefresh, hasAcceptButton = true }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const cookie = Cookies.get(cookieName);
  const initialValue = cookie ? cookie.split(',') : ['YES'];
  if (!initialValue.includes('YES')) {
    initialValue.push('YES');
  }

  const [agreedCategories, setAgreedCategories] = useState(initialValue);
  const toggleCategory = useCallback(
    category => {
      setAgreedCategories(oldValue => {
        const newValue = oldValue.includes(category) ? oldValue.filter(c => c != category) : [...oldValue, category];
        if (!hasAcceptButton) {
          setConsentCookie(newValue);
        }
        return newValue;
      });
    },
    [setAgreedCategories, hasAcceptButton]
  );

  const acceptSelection = useCallback(() => {
    setConsentCookie(agreedCategories);
    setRefresh && setRefresh(true);
  }, [agreedCategories, setRefresh]);

  return (
    <Container
      variant="narrow"
      sx={{
        maxHeight: '100%',
        bg: 'white',
        p: [24, 4],
        borderRadius: [0, 2],
        height: ['100%', 'auto'],
        overflowY: 'auto',
        mx: [0, 4],
      }}
    >
      <Styled.h3 sx={{ mt: 0, mb: 2 }}>{translate('cookieConsent.categoryTitle')}</Styled.h3>
      <Styled.p>{translate('cookieConsent.categoryDescription')}</Styled.p>
      {allCategories.map(category => {
        const mandatory = category === 'YES';
        return (
          <Flex
            key={category}
            sx={{
              mb: 4,
              alignItems: 'flex-start',
              lineHeight: 'normal',
            }}
          >
            <Switch
              checked={agreedCategories.includes(category)}
              disabled={mandatory}
              onChange={() => toggleCategory(category)}
            />
            <Box sx={{ flex: 1 }}>
              <Box sx={{ fontSize: 2, fontWeight: 'medium', mb: 1 }}>
                {translate(`cookieConsent.${category}.label`)}
              </Box>
              <Box>{translate(`cookieConsent.${category}.description`)}</Box>
            </Box>
          </Flex>
        );
      })}
      {hasAcceptButton && <Button onClick={acceptSelection}>{translate('cookieConsent.accept')}</Button>}
    </Container>
  );
};

const CookieConsent = props => {
  const [showCategories, setShowCategories] = useState(false);
  const [refresh, setRefresh] = useState();
  const cookie = Cookies.get(cookieName);
  useEffect(() => {
    if (!cookie) {
      return;
    }
    const categories = cookie.split(',');
    analytics.setCookieConsent(categories, false);
    loadVine(categories);
  }, [cookie]);

  const initialValue = cookie ? cookie.split(',') : allCategories;
  const alreadyAcceptedByUser = cookie && initialValue.includes('YES');
  if (!initialValue.includes('YES')) {
    initialValue.push('YES');
  }

  if (alreadyAcceptedByUser) {
    return null;
  } else if (!showCategories) {
    return <AcceptAllOverlay setRefresh={setRefresh} showCategories={() => setShowCategories(true)} />;
  } else {
    return (
      <div
        role="dialog"
        aria-modal="true"
        aria-labelledby="cookie-consent-title"
        aria-describedby="cookie-consent-description"
      >
        <div id="cookie-consent-description">
          <Flex
            sx={{
              maxHeight: '100%',
              overflowY: 'scroll',
              position: 'fixed',
              justifyContent: 'center',
              alignItems: ['flex-start', 'center'],
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              bg: 'rgba(0,0,0,.5)',
              zIndex: 999,
            }}
          >
            <SelectCategories setRefresh={setRefresh} />
          </Flex>
        </div>
      </div>
    );
  }
};

const ccButton = ({ children, ...props }) => (
  <Button id="ccButton" variant="small" sx={{ flex: 'none', ml: [2, 3] }} {...props}>
    {children}
  </Button>
);

export default props => {
  const [hasMounted, setHasMounted] = React.useState(false);
  // do not show cookie consent on preview
  const show = isBrowser && !window.location.pathname?.startsWith('/preview/');

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted || !show) {
    return null;
  }

  return <CookieConsent />;
};
