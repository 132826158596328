/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { getTranslate } from 'react-localize-redux';
import { loadProductsForCountry } from '../state/catalog';
import * as api from '../utils/api';
import getLanguage from '../utils/getLanguage';
import { CountryCode, localizedCountryNames } from '../utils/countryCode';

const CountryOption = ({ code, name }) => {
  // flag images: npm i svg-country-flags
  if (code === 'uk') {
    code = 'gb';
  }
  return (
    <Flex sx={{ alignItems: 'center' }}>
      <img
        src={'/flags/' + code.toLowerCase() + '.svg'}
        alt={'lippu ' + name}
        sx={{ maxHeight: '16px', width: '30px' }}
        onError={e => (e.target.style = 'visibility: hidden')}
        aria-hidden="true"
      />
      <span sx={{ ml: 2, flexGrow: 1 }}>{name}</span>
    </Flex>
  );
};

export default ({ ignoreCountries = [] }) => {
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));
  const translate = getTranslate(useSelector(state => state.localize));
  const currentCountryCode = useSelector(state => state.parcelFlow.country);
  const currentCountry = localizedCountryNames.find(c => c.code === currentCountryCode);
  const hideSelection = ignoreCountries.includes(currentCountryCode);

  const filterCountries = useCallback(
    async query => {
      const q = query.toLowerCase();
      let enabledCountries = [
        // CountryCode.Finland,
        CountryCode.Aland,
        CountryCode.Estonia,
        CountryCode.Latvia,
        CountryCode.Lithuania,
        CountryCode.Sweden,
        CountryCode.Denmark,
        CountryCode.Germany,
        CountryCode.Belgium,
        CountryCode.Netherlands,
        CountryCode.Luxembourg,
        CountryCode.Austria,
        CountryCode.Spain,
        CountryCode.France,
        CountryCode.Croatia,
        CountryCode.Portugal,
        CountryCode.Italy,
        CountryCode.Ireland,
        CountryCode.Slovenia,
        CountryCode.Slovakia,
        CountryCode.Hungary,
        CountryCode.Poland,
        CountryCode.Czech,
      ];
      if (process.env.GATSBY_ACTIVE_ENV === 'test' || process.env.NODE_ENV === 'development') {
        enabledCountries = [
          // CountryCode.Finland,
          CountryCode.Aland,
          CountryCode.Estonia,
          CountryCode.Latvia,
          CountryCode.Lithuania,
          CountryCode.Sweden,
          CountryCode.Denmark,
          CountryCode.Germany,
          CountryCode.Belgium,
          CountryCode.Netherlands,
          CountryCode.Luxembourg,
          CountryCode.Austria,
          CountryCode.Spain,
          CountryCode.France,
          CountryCode.Croatia,
          CountryCode.Portugal,
          CountryCode.Italy,
          CountryCode.Ireland,
          CountryCode.GreatBritain,
          CountryCode.Slovenia,
          CountryCode.Slovakia,
          CountryCode.Hungary,
          CountryCode.Poland,
          CountryCode.Czech,
          CountryCode.Russia,
        ];
      }
      return localizedCountryNames
        .filter(c => !enabledCountries || enabledCountries.includes(c.code))
        .filter(c => Object.values(c.name).some(n => n.toLowerCase().includes(q)))
        .sort((a, b) => a.name[language].localeCompare(b.name[language]));
    },
    [language]
  );

  const selectCountry = useCallback(country => dispatch(loadProductsForCountry(country.code, true)), [dispatch]);

  const formatCountry = useCallback(country => country.name[language] || country.name.fi, [language]);

  const Option = useCallback(
    props => {
      let { code, name } = props.data;
      name = name[language] || name.fi;

      return (
        <components.Option {...props}>
          <CountryOption code={code} name={name} />
        </components.Option>
      );
    },
    [language]
  );

  const SingleValue = useCallback(
    props => {
      let { code, name } = props.data;
      name = name[language] || name.fi;

      return (
        <components.SingleValue {...props}>
          <CountryOption code={code} name={name} />
        </components.SingleValue>
      );
    },
    [language]
  );

  const selectStyles = {
    container: base => ({
      ...base,
      paddingBottom: '8px',
      maxWidth: ['100%', null, '300px'],
    }),

    control: base => ({
      ...base,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      minHeight: 'auto',
      padding: 8,
    }),
    placeholder: base => ({ ...base, marginLeft: '8px' }),
    // indicatorsContainer: base => ({ display: 'none' }),
    valueContainer: base => ({ ...base, padding: 0 }),
    singleValue: base => ({ ...base, width: '100%' }),
    input: (base, ...other) => {
      return {
        ...base,
        margin: 0,
        marginLeft: '10px',
        padding: '1px 0 0 0',
        height: '21px',
        boxSizing: 'content-box',
      };
    },
    menu: base => ({ ...base, position: 'relative' }),
  };

  return (
    <AsyncSelect
      menuPortalTarget={document.getElementById('root')}
      placeholder={translate('buyParcel.country.seeList')}
      styles={selectStyles}
      defaultOptions
      cacheOptions
      value={hideSelection ? null : currentCountry}
      // tabSelectsValue={false}
      noOptionsMessage={() => null}
      loadOptions={filterCountries}
      getOptionLabel={formatCountry}
      isOptionSelected={() => false}
      onChange={selectCountry}
      // closeMenuOnSelect={false}
      components={{ Option, SingleValue }}
    />
  );
};
