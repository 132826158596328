/** @jsx jsx */
import { jsx, Flex, Box, useThemeUI } from 'theme-ui';
import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { getTranslate } from 'react-localize-redux';

import Button from '../components/Button';
import { CloseIcon, SearchIcon } from '../components/Icon';
import { hideNotification } from '../state/notifications';
import { getServicePoints, setMapFocus } from '../state/servicePoints';
import { autocompleteAddress } from '../utils/api';
import getLanguage from '../utils/getLanguage';
import Spinner from '../components/Spinner';
import * as api from '../utils/api';

const filterableServices = [
  'EP',
  '-EP',
  'P35',
  'M1',
  'M2',
  'M3',
  'P1',
  'P2',
  'P3',
  //'WU',
  'D1',
  'SL',
];

function ServicePointSearch({ onSearch, ...props }) {
  const dispatch = useDispatch();
  const services = useSelector(state =>
    (state.servicePoints.services || []).filter(s => filterableServices.includes(s.code))
  );
  const notifications = useSelector(state => state.notifications);
  const translate = getTranslate(useSelector(state => state.localize));
  const language = useSelector(state => getLanguage(state));

  const [selectedSuggestion, setSuggestion] = useState({});
  const [inputValue, setInputValue] = useState('');

  const loadOptions = useCallback(
    term => {
      return autocompleteAddress(term, language);
    },
    [language]
  );

  const onInputChange = useCallback(
    (term, { action }) => {
      if (action === 'input-change') {
        setInputValue(term);
        return term;
      } else if (action === 'set-value') {
        setInputValue('');
      }
      // other actions: input-blur and menu-close
    },
    [setInputValue]
  );

  const search = useCallback(
    suggestion => {
      if (notifications[0]) {
        dispatch(hideNotification(notifications[0].id));
      }
      const { postalCode, streetAddress } = suggestion || selectedSuggestion || {};
      dispatch(getServicePoints(postalCode, streetAddress, []));
      onSearch && onSearch();
    },
    [dispatch, selectedSuggestion, services, notifications, onSearch]
  );

  const findSuggestionCoordinates = useCallback(
    async suggestion => {
      const coordinates = await api.getPlaceCoordinates(suggestion.locationId);
      const { Latitude, Longitude } = coordinates;
      if (Latitude && Longitude) {
        dispatch(setMapFocus(Latitude, Longitude));
      }
    },
    [dispatch]
  );

  const selectSuggestion = useCallback(
    suggestion => {
      setSuggestion(suggestion);
      search(suggestion);
      if (suggestion) {
        findSuggestionCoordinates(suggestion);
      } else {
        dispatch(setMapFocus(null));
      }
    },
    [setSuggestion, search]
  );

  const searchSubmit = useCallback(
    e => {
      e && e.preventDefault();
      if (inputValue) {
        search({ postalCode: inputValue.trim() });
      } else {
        search();
      }
    },
    [search, inputValue]
  );

  const handleClearSearch = useCallback(() => {
    setSuggestion({});
    setInputValue('');
    search();
  }, [setSuggestion, setInputValue, search]);

  const { theme } = useThemeUI();

  const selectStyles = {
    container: base => ({
      ...base,
      position: 'static',
      width: '100%',
      minWidth: 160,
      left: 0,
      border: 0,
      px: 0,
    }),
    control: base => ({
      ...base,
      position: 'static',
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      backgroundColor: theme.colors.grayLighter,
    }),
    indicatorsContainer: base => ({ display: 'none' }),
    menu: base => ({
      ...base,
      left: -40,
      right: -48,
      borderRadius: 8,
      paddingLeft: 1,
      paddingRight: 1,
      boxShadow: `inset 0 0 0 1px ${theme.colors.border}, ${theme.boxShadow.dropdown}`,
      width: 'auto',
      '::before, ::after': {
        position: 'absolute',
        top: -15,
        left: 40,
        border: '8px solid transparent',
        borderBottomColor: 'white',
        content: '""',
      },
      '::after': {
        borderWidth: '9px',
        borderBottomColor: theme.colors.border,
        top: -17,
        left: 39,
        zIndex: -1,
      },
    }),
    option: (base, state) => ({
      ...base,
      padding: '6px 12px',
      color: theme.colors.text,
      fontFamily: theme.fonts.body,
      backgroundColor: state.isFocused ? theme.colors.blueLighter : 'none',
      ':hover': { backgroundColor: theme.colors.blueLighter },
      ':first-of-type': { marginTop: theme.space[2] },
      ':last-of-type': { marginBottom: theme.space[2] },
    }),
  };

  const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null;

  return (
    <form onSubmit={searchSubmit} {...props}>
      <Flex
        sx={{
          position: 'relative',
          bg: 'grayLighter',
          borderRadius: 2,
        }}
      >
        <Button
          variant="plain"
          type="submit"
          title={translate('servicePoints.search')}
          sx={{ flex: 'none', pl: 3, color: 'secondary' }}
        >
          <SearchIcon aria-hidden={true} />
        </Button>
        <AsyncSelect
          aria-label={translate('servicePoints.searchPlaceholder')}
          placeholder={translate('servicePoints.searchPlaceholder')}
          styles={selectStyles}
          loadOptions={loadOptions}
          getOptionLabel={opt => opt.name}
          isOptionSelected={() => false}
          onInputChange={onInputChange}
          value={selectedSuggestion.postalCode ? selectedSuggestion : null}
          inputValue={inputValue}
          onChange={selectSuggestion}
          noOptionsMessage={() => null}
          menuPortalTarget={portalRoot}
          menuIsOpen={undefined}
          loadingMessage={() => (
            <Box sx={{ py: 2 }}>
              <Spinner />
            </Box>
          )}
        />
        {(inputValue || selectedSuggestion.name) && (
          <Button
            variant="plain"
            type="button"
            title={translate('servicePoints.search')}
            sx={{ flex: 'none', px: 3, color: 'secondary' }}
            onClick={handleClearSearch}
          >
            <CloseIcon />
          </Button>
        )}
      </Flex>
    </form>
  );
}

ServicePointSearch.propTypes = {
  hideFilters: PropTypes.bool,
};

ServicePointSearch.defaultProps = {
  hideFilters: true,
};

export default ServicePointSearch;
